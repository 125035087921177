/**
 * @description filter Set object and return only those permissions with truthy values as array
 * @param {Set}  permissions is a Set structure with values { readResource: Bool, writeResource: Bool, createResource: Bool, deleteResource: Bool }
 * @returns {Array} return array with possible values ['read', 'write', 'delete', 'create'], the values are inside if they are true in permissions Set
 */
export const filterPermissions = (permissions) => {
  const permissionsArray = Object
    .keys(permissions)
    .map((permissionKey) => {
      if (permissions[permissionKey]) {
        const indexForSliceResourceWord = permissionKey.indexOf('Resource');
        const permission = permissionKey.slice(0, indexForSliceResourceWord);

        return permission;
      }

      return '';
    })
    .filter((permission) => permission.length > 0);

  return permissionsArray;
};
