import {
  SET_CURRENT_USER,
  SET_CURRENT_USER_ROLE,
  TOGGLE_SIDENAV,
} from './actionTypes';
import { getCurrentUser, getDataByUsernameAndPathname } from '../../api/common-service';
import { AUTHORITIES_PATH } from '../../constants/url-related';

export const setCurrentUser = (user) => ({ type: SET_CURRENT_USER, user });

export const setCurrentUserRole = (isAdmin) => ({ type: SET_CURRENT_USER_ROLE, isAdmin });

export const fetchCurrentUser = () => async (dispatch) => {
  try {
    const headers = { 'Content-Type': 'application/hal+json', Accept: 'application/hal+json' };
    const { data: user } = await getCurrentUser(headers);
    const { data } = await getDataByUsernameAndPathname(user.username, AUTHORITIES_PATH);
    const { _embedded: { authorityEntities } } = data;
    const isAdmin = authorityEntities.filter((item) => (item.id.authority === 'ROLE_ADMIN' || item.id.authority === 'ROLE_SUPPORT')).length > 0;

    dispatch(setCurrentUser(user));
    dispatch(setCurrentUserRole(isAdmin));
  } catch (err) { throw err.message; }
};

export const toggleSideNav = (isSideNavOpen) => ({ type: TOGGLE_SIDENAV, isSideNavOpen });
