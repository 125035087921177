/* eslint-disable max-len */

import { get, post, patch } from './api';
import { dataNamespaceUrl } from '../constants/url-related';

/**
 * @description update customer information by customerId and send patch request, the total request will be ('/data/customerEntities/${customerId}, informationRequest)')
 * @param  {String} customerId
 * @param  {Object} informationRequest
 * @returns {Promise}
 */
export const updateCustomerInfo = (customerId, informationRequest) => patch(`${dataNamespaceUrl}/customerEntities/${customerId}`, informationRequest);

/**
 * @description update certificate information by certificateId and send patch request, the total request will be ('/data/certificateEntities/${certificateId}, informationRequest)')
 * @param  {String} certificateId
 * @param  {Object} informationRequest
 * @returns {Promise}
 */
export const updateCertificateInfo = (certificateId, informationRequest) => patch(`${dataNamespaceUrl}/certificateEntities/${certificateId}`, informationRequest);

/**
 * @description create new certificate, the total request will be ('/data/certificateEntities, informationRequest)')
 * @param  {Object} informationRequest
 * @returns {Promise}
 */
export const addNewCertificate = (informationRequest) => post(`${dataNamespaceUrl}/certificateEntities`, informationRequest);

/**
 * @description Fetch id for provided groupName and userName, total request will be ('/data/groupMemberEntities/search/findByGroupGroupNameAndUserUsername?groupName=${groupName}&username=${userName}')
 * @param  {String} groupName
 * @param  {String} userName
 * @returns {Promise}
 */
export const getIdByGroupNameAndUserName = (groupName, userName) => get(`${dataNamespaceUrl}/groupMemberEntities/search/findByGroupGroupNameAndUserUsername?groupName=${groupName}&username=${userName}`);
