import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Switch,
} from '@material-ui/core';

const UsersTable = ({ headers, users, currentUser, handleIsActive }) => (
  <Table>
    <TableHead>
      <TableRow>
        {headers.map((header) => (<TableCell key={header}>{header}</TableCell>))}
      </TableRow>
    </TableHead>
    <TableBody>
      {users.map((row) => (
        <TableRow key={row.username}>
          <TableCell>{row.username}</TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell>
            <Link to={`/users/${row.username}`}>
              {row.email}
            </Link>
          </TableCell>
          <TableCell>{row.companyName}</TableCell>
          <TableCell>{row.registered}</TableCell>
          <TableCell>{row.validUntil}</TableCell>
          <TableCell>{row.eula}</TableCell>
          <TableCell>
            <Switch
              checked={row.isActive}
              onChange={() => { handleIsActive(row); }}
              value={row.name}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              disabled={row.username === currentUser}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

UsersTable.propTypes = {
  headers: propTypes.array.isRequired,
  users: propTypes.array.isRequired,
  currentUser: propTypes.string.isRequired,
  handleIsActive: propTypes.func.isRequired,
};

export default UsersTable;
