/* eslint-disable max-len */

import { get, del } from './api';
import { dataNamespaceUrl } from '../constants/url-related';

/**
 * @description Fetch group by groupName total request will be ('/data/groupEntities/search/findByGroupName?groupName=${groupName}')
 * @param {String} groupName
 * @returns {Promise}
 */
export const getGroupByGroupName = (groupName) => get(`${dataNamespaceUrl}/groupEntities/search/findByGroupName?groupName=${groupName}`);

/**
 * @description Fetch group by groupGroupName total request will be ('/data/groupMemberEntities/search/findByGroupGroupName?groupName=${groupName}')
 * @param {String} groupName
 * @returns {Promise}
 */
export const getGroupByGroupGroupName = (groupName) => get(`${dataNamespaceUrl}/groupMemberEntities/search/findByGroupGroupName?groupName=${groupName}`);

/**
 * @description get userId by group name and username, total request will be: ('/data/groupMemberEntities/search/findByGroupGroupNameAndUserUsername?groupName=${groupInputName}&username=${username}')
 * @returns {Promise}
 */
export const getUserIdByGroupnameAndUsername = (groupInputName, username) => get(`${dataNamespaceUrl}/groupMemberEntities/search/findByGroupGroupNameAndUserUsername?groupName=${groupInputName}&username=${username}`);

/**
 * @description Get array of Acl Entry Entities, the total request will be ('/data/aclEntryEntities/search/findBySidSid?sid=${groupInputName}')
 * @returns {Promise}
 */
export const getAclEntryEntitiesGroupByGroupname = (groupInputName) => get(`${dataNamespaceUrl}/aclEntryEntities/search/findBySidSid?sid=${groupInputName}`);

/**
 * @description GET request will hit ent-point: ('/data/resourceEntities/search/findByKindAndName?kind=${kind}&name=Axilion License Server')
 * @returns {Promise}
 */
export const getResourceSortedByKindAndName = (name, kind) => get(`${dataNamespaceUrl}/resourceEntities/search/findByKindAndName?kind=${kind}&name=Axilion License Server`);

/**
 * @description GET request will hit ent-point: ('/data/aclSidEntities/search/findBySid?sid=${groupInputName}')
 * @returns {Promise}
 */
export const getAclSidEntitiesByGroupname = (groupInputName) => get(`${dataNamespaceUrl}/aclSidEntities/search/findBySid?sid=${groupInputName}`);
/**
 * @description GET request will hit ent-point: ('/data/resourceEntities/search/findByKindAndNameAndVersion?kind=${tabValue === 'applications' ? 'APPLICATION' : 'PROJECT'}&name=${resourceNameForEdit}&version=${versionToEdit}')
 * @returns {Promise}
 */
export const gerResourceSortedByKindByNameAndVersion = (kind, name, version) => get(`${dataNamespaceUrl}/resourceEntities/search/findByKindAndNameAndVersion?kind=${kind === 'applications' ? 'APPLICATION' : 'PROJECT'}&name=Axilion License Server&version=1.5-SNAPSHOT`);
/**
 * @description DELETE request will hit ent-point: ('/data/resourceEntities/com.axilion.service:license:jar:1.5-SNAPSHOT')
 * @returns {Promise}
 */
export const deleteResource = (id) => del(`${dataNamespaceUrl}/resourceEntities/com.axilion.service:license:jar:1.5-SNAPSHOT`);
/**
 * @description GET request will hit ent-point: ('/data/featureEntities/search/findByResourceId?resourceId=${resourceId}')
 * @returns {Promise}
 */
export const getFeatureEntitiesFindByResourceId = (resourceId) => get(`${dataNamespaceUrl}/featureEntities/search/findByResourceId?resourceId=${resourceId}`);
/**
 * @description GET request will hit ent-point: ('/data/featureEntities')
 * @returns {Promise}
 */
export const getAllFeatures = () => get(`${dataNamespaceUrl}/featureEntities`);
