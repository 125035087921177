import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { Drawer, Divider, IconButton, ListItemIcon, ListItem, ListItemText } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import SideNavStyles from './SideNav.styles';
import { toggleSideNav } from '../../store/actions';
import logo from '../assets/logo.svg';

const useStyles = makeStyles((theme) => (SideNavStyles(theme)));

const SideNav = () => {
  const classes = useStyles();
  const { isAdmin } = useSelector((state) => state.currentUserData);
  const { isSideNavOpen } = useSelector((state) => state.toggleSideNav);
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectTo = (route) => {
    history.push(route);
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !isSideNavOpen && classes.drawerPaperClose),
      }}
      open={isSideNavOpen}
    >
      <div className={classes.toolbarIcon}>
        <img src={logo} alt="axilion-logo" className={classes.logo} />
        {isAdmin && (
          <IconButton className={classes.chevronLeft} onClick={() => { dispatch(toggleSideNav(!isSideNavOpen)); }}>
            <ChevronLeftIcon />
          </IconButton>
        )}
      </div>
      <Divider />
      <div className={classes.sideBarItems}>
        {isAdmin && (
          <ListItem button onClick={() => (redirectTo('/admin'))}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Administration" />
          </ListItem>
        )}
      </div>
    </Drawer>
  );
};

export default SideNav;
