import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseRounded from '@material-ui/icons/CloseRounded';
import LibraryAddOutlined from '@material-ui/icons/LibraryAddOutlined';

import {
  AppBar, Typography, Tabs, Tab, Paper, Grid, Container, Box,
  List, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction,
  Checkbox, ExpansionPanel, ExpansionPanelSummary,
  ExpansionPanelDetails, TextField, Table,
  TableRow, TableCell, TableHead, TableBody, Fab,
  FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel,
  IconButton, Button, InputLabel, Select, MenuItem, Divider, Tooltip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Header,
  SideNav,
  DialogWindow,
  Loader,
  InputSearch,
  UsersTable,
} from '../../common';

import GroupStyles from './Group.styles';

import {
  getGroupByGroupName,
  getGroupByGroupGroupName,
  getUserIdByGroupnameAndUsername,
  getAclEntryEntitiesGroupByGroupname,
  getAclSidEntitiesByGroupname,
  getResourceSortedByKindAndName,
  gerResourceSortedByKindByNameAndVersion,
  deleteResource as deleteResourceEntity,
  getFeatureEntitiesFindByResourceId,
} from '../../api/group-service';
import {
  getDataForPathName,
  updateDataByIdAndPathname,
  getDataByProvidedArrayOfUrls,
  deleteGroupMembersById,
  postDataForPathName,
  deleteDataByPathnameAndId,
} from '../../api/common-service';

import {
  composeUserObject,
  composeResourceObjectWithPermissions,
  composeProjectsOrApplicationsWithVersionAndPermissions,
} from '../../utils/helpers';

import {
  USER_ENTITIES_PATH,
  GROUP_MEMBER_ENTITIES_PATH,
  ACL_OBJECT_IDENTITY_ENTITIES_PATH,
  RESOURCE_ENTITIES_PATH,
  GROUP_ENTITIES_PATH,
  ACL_SID_ENTITIES_PATH,
  ACL_ENTRY_ENTITIES_PATH,
  FEATURE_ENTITIES_PATH,
  dataNamespaceUrl,
} from '../../constants/url-related';

const useStyles = makeStyles((theme) => (GroupStyles(theme)));
const FORM_DEFAULT_STATE = {
  resourcename: '',
  resourceversion: '',
  featurename: '',
};
const getResourceMask = (type) => {
  switch (type) {
    case 1:
      return 'read';
    case 2:
      return 'write';
    case 4:
      return 'create';
    case 8:
      return 'delete';
    default:
      return 'read';
  }
};
const setResourceMask = (type) => {
  switch (type) {
    case 'readResource':
      return 1;
    case 'writeResource':
      return 2;
    case 'createResource':
      return 4;
    case 'deleteResource':
      return 8;
    default:
      return 1;
  }
};

const FEATURES = [
  'allow.edit.axp',
  'allow.greenwave.perspective',
  'allow.compare.perspective',
  'allow.open.jerusalem.axls',
  'allow.import.from.inbar',
  'export.mot.unified',
  'allow.generate.aimsun.code',
  'allow.corridors',
  'allow.tlg.breaker',
  'export.avivim',
  'export.mabar.unified',
  'allow.generate.tlc.code',
];
/**
 *@file Group component. Group component is loading when the route /group/name-of-user
 * is loaded it is accessed from /admin screen, Groups tab when you click on a group Name
 * Tabs for Information, Projects, Applications, Members
 */
const Group = () => {
  const match = useRouteMatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const TAB_NAMES = [t('information'), t('projects'), t('applications'), t('members')];
  const { params: { groupName } } = match;
  const [groupInputName, setGroupInputName] = useState(groupName);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [openNewFeatureModal, setOpenNewFeatureModal] = useState(false);
  const [openNewVersionModal, setOpenNewVersionModal] = useState(false);
  const [tabValue, setTabValue] = useState(TAB_NAMES[0]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [ROWS_USERS, setRowUsers] = useState([]);
  const [ALL_ROWS_USERS, setAllRowUsers] = useState([]);
  const [ROWS_PROJECTS, setProjects] = useState([]);
  const [ROWS_APPLICATIONS, setApplications] = useState([]);
  const [formState, setFormState] = useState(FORM_DEFAULT_STATE);
  const [permissions, setPermissions] = useState({
    readResource: true,
    writeResource: false,
    createResource: false,
    deleteResource: false,
  });
  const [featureEntities, setFeatureEntities] = useState([]);
  const [resourceEntities, setResourceEntities] = useState([]);
  const [resourceName, setResourceName] = useState(null);
  const [versionToEdit, setVersionToEdit] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [versionForResource, setVersionForResource] = useState('');
  const [isFeatureForAllVersions, setIsFeatureForAllVersions] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [currentRowForDeletion, setCurrentRowForDeletion] = useState(null);
  const [isCloning, setIsCloning] = useState(false);
  const [cloneFromVersion, setCloneFromVersion] = useState(null);
  const { readResource, writeResource, createResource, deleteResource } = permissions;
  const error = [readResource, writeResource, createResource, deleteResource].filter((v) => v).length < 1;
  const { currentUser } = useSelector((state) => state.currentUserData);
  const [isLoadingProjectsAndApplications, setIsLoadingProjectsAndApplications] = useState(false);
  const [isLoadingGroupMembers, setIsLoadingGroupMembers] = useState(false);

  const PROJECT_VALUE = 'PROJECT';
  const APPLICATION_VALUE = 'APPLICATION';

  const handleChangePermission = (name) => (event) => {
    setPermissions({ ...permissions, [name]: event.target.checked });
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getFeatures = async (currentResourceName) => {
    const kind = tabValue === t('applications') ? APPLICATION_VALUE : PROJECT_VALUE;

    setResourceName(currentResourceName);
    const {
      data: { _embedded: { resourceEntities: resources } },
    } = await getResourceSortedByKindAndName(currentResourceName, kind);
    const resourceEntitiesUrls = resources.map((item) => item._links.features.href);

    let features = await getDataByProvidedArrayOfUrls(resourceEntitiesUrls);

    features = features.map((feature) => (feature.data._embedded.featureEntities));
    const data = resources.map((item, i) => {
      if (!item.version.includes('SNAPSHOT')) {
        return {
          version: item.version,
          features: features[i],
        };
      }

      return undefined;
    }).filter((el) => (el !== undefined));

    setResourceEntities(resources);
    setFeatureEntities(data);
  };

  /**
 * @description removeResourceAction, delete PROJECT or APPLICATION based on kind and id properties in the project object
 * @param {object} project - project to be deleted with properties example: { id: 76, kind: "PROJECT", name: "pp-1",
 * permissions: ['read', 'write', 'create', 'delete'], versions: ['vv-1'] }
 */
  const removeResourceAction = async (project) => {
    const {
      data: { _embedded: { aclObjectIdentityEntities } },
    } = await getDataForPathName(ACL_OBJECT_IDENTITY_ENTITIES_PATH);
    const identityToDelete = aclObjectIdentityEntities
      .filter((entity) => (project.id === Number(entity.objectIdIdentity)));

    identityToDelete.forEach((identity) => {
      deleteDataByPathnameAndId(ACL_OBJECT_IDENTITY_ENTITIES_PATH, identity.id);
    });
    const kind = tabValue === t('projects') ? PROJECT_VALUE : APPLICATION_VALUE;
    const { data: { _embedded: { resourceEntities: resources } } } = await getResourceSortedByKindAndName(project.name, kind);

    resources.forEach((resource) => {
      deleteDataByPathnameAndId(RESOURCE_ENTITIES_PATH, resource.id);
    });
    if (kind === PROJECT_VALUE) {
      const projectsAfterDeletion = ROWS_PROJECTS.filter((currentProject) => currentProject.name !== project.name);

      setProjects(projectsAfterDeletion);
    } else if (kind === APPLICATION_VALUE) {
      const applicationsAfterDeletion = ROWS_APPLICATIONS.filter((currentApp) => currentApp.name !== project.name);

      setApplications(applicationsAfterDeletion);
    }

    setResourceEntities([]);
    setFeatureEntities([]);
    setIsDeleteDialogOpen(false);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    if (!isExpanded) {
      setFeatureEntities([]);
    } else {
      getFeatures(panel);
    }

    setExpanded(isExpanded ? panel : false);
  };

  const toggleModalVisibility = (isOpen) => {
    setOpenModal(isOpen);
  };

  const toggleModalVisibilityVersion = (isOpen) => {
    setOpenNewVersionModal(isOpen);
  };

  const toggleModalVisibilityDelete = (isDeleteModalOpen) => {
    setIsDeleteDialogOpen(isDeleteModalOpen);
  };

  const handleSearch = ({ target: { value: v } }) => {
    if (v === '') {
      setFilteredUsers(ROWS_USERS);

      return;
    }
    const filteredArrUsers = ROWS_USERS.filter(
      (item) => (
        item.name.toLowerCase().includes(v.toLowerCase())
        || item.email.toLowerCase().includes(v.toLowerCase())
        || item.username.toLowerCase().includes(v.toLowerCase())
        || item.companyName.toLowerCase().includes(v.toLowerCase())
      ),
    );

    setFilteredUsers(filteredArrUsers);
  };

  const handleIsActive = (user) => {
    const userRequest = {
      enabled: !user.isActive,
    };

    updateDataByIdAndPathname(user.username, USER_ENTITIES_PATH, userRequest).then(() => {
      const users = filteredUsers.map((item) => {
        if (item.email === user.email) {
          const obj = item;

          obj.isActive = !obj.isActive;

          return obj;
        }

        return item;
      });

      setFilteredUsers(users);
    });
  };

  const getGroupMembers = async () => {
    setIsLoadingGroupMembers(true);
    let usersInfo;

    let userEntities;

    try {
      const { data: { _embedded: { groupMemberEntities } } } = await getGroupByGroupGroupName(groupInputName);
      const groupMembersUrlArrays = groupMemberEntities.map((groupMember) => groupMember._links.user.href);

      userEntities = await getDataByProvidedArrayOfUrls(groupMembersUrlArrays);
      userEntities = userEntities.map(({ data }) => (data));

      const urlsForNameAndEmail = userEntities.map((url) => (url._links.customers.href));
      const urlsForEulas = userEntities.map((url) => (url._links.eula.href));
      const urlsForCertificates = userEntities.map((url) => (url._links.certificates.href));
      const namesAndEmailsInfo = await getDataByProvidedArrayOfUrls(urlsForNameAndEmail);
      const certificatesInfo = await getDataByProvidedArrayOfUrls(urlsForCertificates);
      const eulasInfo = await getDataByProvidedArrayOfUrls(urlsForEulas);

      usersInfo = namesAndEmailsInfo.map(({ data: { _embedded: { customerEntities: customersData } } }, i) => {
        if (customersData.length > 0) {
          let { data: { _embedded: { certificateEntities } } } = certificatesInfo[i];

          certificateEntities = certificateEntities.sort((a, b) => (new Date(b.createdDate) - new Date(a.createdDate)));

          return composeUserObject(customersData[0], userEntities[i], eulasInfo[i].data, certificateEntities[0] || { organization: 'N/A' });
        }

        return undefined;
      }).filter((el) => (el !== undefined));
    } catch (err) {
      throw err.message;
    } finally {
      setRowUsers(usersInfo);
      setFilteredUsers(usersInfo);
      setIsLoadingGroupMembers(false);
    }
  };

  const handleGroupChange = ({ target }) => {
    const { value } = target;

    setGroupInputName(value);
    try {
      const data = { groupName: value };

      updateDataByIdAndPathname(selectedGroupId, GROUP_ENTITIES_PATH, data);
    } catch (err) {
      throw err.message;
    }
  };

  const handleCheckboxToggle = async (elementIndex) => {
    const user = ALL_ROWS_USERS[elementIndex];

    try {
      if (user.checked) {
        const { data: { id } } = await getUserIdByGroupnameAndUsername(groupInputName, user.username);

        await deleteGroupMembersById(id);
        const filteredArray = ROWS_USERS.filter((userItem) => userItem.id !== user.customerId);

        setRowUsers([...filteredArray]);
      } else {
        const groupMemberObject = {
          user: `${dataNamespaceUrl}/userEntities/${user.username}`,
          group: `${dataNamespaceUrl}/groupEntities/${selectedGroupId}`,
        };

        await postDataForPathName(GROUP_MEMBER_ENTITIES_PATH, groupMemberObject);
        const { _links: {
          customers: { href: customersUrl },
          eula: { href: eulaUrl },
        } } = user;
        const arrayOfUrls = [customersUrl, eulaUrl];
        const [{ data: { _embedded: { customerEntities } } }, eula] = await getDataByProvidedArrayOfUrls(arrayOfUrls);
        const userData = {
          username: user.username,
          enabled: user.enabled,
        };

        const userToBeAddedToGroup = composeUserObject(customerEntities[0], userData, eula.data, { organization: 'N/A' });
        const newArrayOfUsers = [...ROWS_USERS, userToBeAddedToGroup];

        setRowUsers(newArrayOfUsers);
      }
      user.checked = !user.checked;
      setAllRowUsers([...ALL_ROWS_USERS]);
    } catch (err) {
      throw err.message;
    }
  };

  const handleDeleteResource = () => {
    removeResourceAction(currentRowForDeletion);
  };

  const handleCancelDeleteWindow = () => {
    setIsDeleteDialogOpen(false);
  };

  const getUsersEntities = async () => {
    let usersInfo = [];

    try {
      const { data:
        { _embedded: { userEntities } } } = await getDataForPathName(USER_ENTITIES_PATH);
      const userEntitiesUrls = userEntities.map((userEntity) => userEntity._links.customers.href);
      const customers = await getDataByProvidedArrayOfUrls(userEntitiesUrls);

      usersInfo = customers.map((customer, i) => {
        if (customer.data._embedded.customerEntities.length === 0) {
          return userEntities[i];
        }
        const currentCustomer = customer.data._embedded.customerEntities[0];

        return {
          ...userEntities[i],
          customerName: `${currentCustomer.firstName} ${currentCustomer.lastName}`,
          customerId: currentCustomer.id,
        };
      });
    } catch (err) {
      throw err.message;
    } finally {
      setAllRowUsers(usersInfo);
    }
  };

  const getGroupId = async () => {
    try {
      const { data: { id: groupId } } = await getGroupByGroupName(groupInputName);

      setSelectedGroupId(groupId);
    } catch (err) {
      throw err.message;
    }
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const getResources = async () => {
    let resources;

    setIsLoadingProjectsAndApplications(true);
    try {
      const { data } = await getAclEntryEntitiesGroupByGroupname(groupInputName);
      const { _embedded: { aclEntryEntities: aclEntryEntitiesGroup } } = data;
      const aclObjectIdentity = aclEntryEntitiesGroup.map((url) => (url._links.aclObjectIdentity.href));
      const aclObjectIdentities = await getDataByProvidedArrayOfUrls(aclObjectIdentity);
      const objectIdIdentitiesUrls = aclObjectIdentities.map((item) => (
        item.data._links.objectIdClass.href));
      const objectIdIdentities = await getDataByProvidedArrayOfUrls(objectIdIdentitiesUrls);
      const featureEntitiesUrls = [];
      const resourceEntitiesUrls = [];

      objectIdIdentities.forEach((identity, index) => {
        if (identity.data.clazz.includes('FeatureEntity')) {
          featureEntitiesUrls.push(
            `${dataNamespaceUrl}/featureEntities/${aclObjectIdentities[index].data.objectIdIdentity}`,
          );
        } else {
          resourceEntitiesUrls.push(
            `${dataNamespaceUrl}/resourceEntities/${aclObjectIdentities[index].data.objectIdIdentity}`,
          );
        }
      });
      const featureAndResourceUrls = featureEntitiesUrls.concat(resourceEntitiesUrls);
      const res = await getDataByProvidedArrayOfUrls(featureAndResourceUrls);

      resources = res.map((resource, i) => (
        { ...resource.data, mask: getResourceMask(aclEntryEntitiesGroup[i].mask) }
      ));
    } catch (err) {
      throw err.message;
    } finally {
      if (resources) {
        const resMap = new Map();

        resources = resources.filter((i) => {
          if (i.version) {
            return !i.version.includes('SNAPSHOT');
          }

          return i.kind === PROJECT_VALUE
            || i.kind === APPLICATION_VALUE;
        });

        resources.forEach((resource) => {
          if (resMap.has(resource.id)) {
            const obj = resMap.get(resource.id);

            obj.mask.push(resource.mask);
          } else {
            resMap.set(resource.id, {
              name: resource.name,
              version: resource.version,
              kind: resource.kind,
              mask: [resource.mask],
            });
          }
        });
        let uniqueArray = Array.from(resMap.values());

        resMap.clear();

        uniqueArray.forEach((resource) => {
          if (resMap.has(resource.name)) {
            const obj = resMap.get(resource.name);

            obj.data.push({ version: resource.version, permissions: resource.mask });
          } else {
            resMap.set(resource.name, {
              name: resource.name,
              data: [{ version: resource.version, permissions: resource.mask }],
              kind: resource.kind,
            });
          }
        });

        uniqueArray = Array.from(resMap.values());
        resMap.clear();
        setProjects(uniqueArray.filter((i) => (i.kind === PROJECT_VALUE)));
        setApplications(uniqueArray.filter((i) => (i.kind === APPLICATION_VALUE)));
        setIsLoadingProjectsAndApplications(false);
      }
    }
  };

  const addNewFeature = async (featureName, singleResourceHref) => {
    if (featureName === '') {
      return;
    }

    if (singleResourceHref) {
      const requestObject = {
        name: featureName,
        resource: singleResourceHref,
      };

      await postDataForPathName(FEATURE_ENTITIES_PATH, requestObject);

      return;
    }

    try {
      resourceEntities
        .filter((resourceEntity) => {
          if (!isFeatureForAllVersions) {
            return resourceEntity.version === versionToEdit;
          }

          return resourceEntity;
        })
        .forEach(async (resourceEntity) => {
          const { _links: { resourceEntity: { href: resourceEntityHref } } } = resourceEntity;
          const requestObject = {
            name: featureName,
            resource: resourceEntityHref,
          };

          await postDataForPathName(FEATURE_ENTITIES_PATH, requestObject);
        });
    } catch (err) {
      throw err.message;
    } finally {
      getFeatures(resourceName);
      setOpenNewFeatureModal(false);
      setFormState(FORM_DEFAULT_STATE);
    }
  };

  const addNewResource = async (name, kind, version, isAddingVersion, features) => {
    if (name.trim() === '' || version.trim() === '' || error) {
      return;
    }
    let sidUrl;

    try {
      const {
        data: { _links: { self: { href } } },
      } = await getAclSidEntitiesByGroupname(groupInputName);

      sidUrl = href;
    } catch (err) {
      const requestObject = {
        principal: true,
        sid: groupInputName,
      };
      const { data: { _links: { self: { href } } } } = await postDataForPathName(ACL_SID_ENTITIES_PATH, requestObject);

      sidUrl = href;
    }
    try {
      const resourceObject = {
        name,
        kind,
        version,
      };

      const responseResourceEntities = await postDataForPathName(RESOURCE_ENTITIES_PATH, resourceObject);
      const resourceEntitySelfLink = responseResourceEntities.data._links.self.href;
      const { data: { id: objectIdIdentity } } = responseResourceEntities;
      const aclObjectRequest = {
        objectIdClass: `${dataNamespaceUrl}/aclClassEntities/1`,
        objectIdIdentity,
      };
      const {
        data: { _links: { aclObjectIdentityEntity: { href: aclObjectIdentity } } },
      } = await postDataForPathName(ACL_OBJECT_IDENTITY_ENTITIES_PATH, aclObjectRequest);

      Object.entries(permissions).forEach(async (permission) => {
        if (permission[1]) {
          await postDataForPathName(ACL_ENTRY_ENTITIES_PATH, {
            aceOrder: setResourceMask(permission[0]), // same as mask - 1,2,4,8
            aclObjectIdentity,
            auditFailure: true,
            auditSuccess: true,
            granting: true,
            mask: setResourceMask(permission[0]),
            sid: sidUrl,
          });
        }
      });
      if (kind === PROJECT_VALUE) {
        if (isAddingVersion) {
          if (features) {
            features.forEach((feature) => {
              addNewFeature(feature, resourceEntitySelfLink);
            });
          }

          const allProjectsWithAllVersionsAndPermissions = composeProjectsOrApplicationsWithVersionAndPermissions(
            ROWS_PROJECTS,
            name,
            version,
            permissions,
          );

          setProjects(allProjectsWithAllVersionsAndPermissions);
          getFeatures(resourceName);
        } else {
          const projectObject = {
            id: objectIdIdentity,
            kind: PROJECT_VALUE,
            name,
            version,
          };
          const newProjectToBeAdded = composeResourceObjectWithPermissions(projectObject, permissions);

          setProjects([...ROWS_PROJECTS, newProjectToBeAdded]);
        }
      } else if (kind === APPLICATION_VALUE) {
        if (isAddingVersion) {
          if (features) {
            features.forEach((feature) => {
              addNewFeature(feature, resourceEntitySelfLink);
            });
          }

          const allApplicationsWithAllVersionsAndPermissions = composeProjectsOrApplicationsWithVersionAndPermissions(
            ROWS_APPLICATIONS,
            name,
            version,
            permissions,
          );

          setApplications(allApplicationsWithAllVersionsAndPermissions);
          getFeatures(resourceName);
        } else {
          const applicationObject = {
            id: objectIdIdentity,
            kind: APPLICATION_VALUE,
            version,
            name,
          };
          const newAppToBeAdded = composeResourceObjectWithPermissions(applicationObject, permissions);

          setApplications([...ROWS_APPLICATIONS, newAppToBeAdded]);
        }
      }
    } catch (err) {
      throw err.message;
    } finally {
      toggleModalVisibility(false);
      toggleModalVisibilityVersion(false);
    }
  };

  /**
   * @function removeVersion
   * @description removeVersion, remove version for kind('PROJECT' or 'APPLICATION')
   * by the name of the version, resourceName(resource can be 'PROJECT' or 'APPLICATION') and tab value('PROJECTS' or 'APPLICATIONS')
   * @param {string} version
  */
  const removeVersion = async (version) => {
    try {
      const { data: { id: resourceId } } = await gerResourceSortedByKindByNameAndVersion(tabValue, resourceName, version);

      const {
        data: { _embedded: { aclObjectIdentityEntities } },
      } = await getDataForPathName(ACL_OBJECT_IDENTITY_ENTITIES_PATH);
      const identityToDelete = aclObjectIdentityEntities
        .filter((entity) => (resourceId === Number(entity.objectIdIdentity)));

      identityToDelete.forEach((identity) => {
        deleteDataByPathnameAndId(ACL_OBJECT_IDENTITY_ENTITIES_PATH, identity.id);
      });

      await deleteResourceEntity(resourceId);
    } catch (err) {
      throw err.message;
    } finally {
      const ROWS_PROJECTS_OR_APPLICATIONS = tabValue === t('projects') ? ROWS_PROJECTS : ROWS_APPLICATIONS;

      const allProjectsOrApplications = ROWS_PROJECTS_OR_APPLICATIONS.map((projectOrApplication) => {
        if (projectOrApplication.name === resourceName) {
          const currentProjectWithoutDeletedVersionAndPermissions = {
            ...projectOrApplication,
            data: projectOrApplication.data.filter((versionObject) => versionObject.version !== version),
          };

          return currentProjectWithoutDeletedVersionAndPermissions;
        }

        return projectOrApplication;
      });

      if (tabValue === t('projects')) {
        setProjects(allProjectsOrApplications);
      } else {
        setApplications(allProjectsOrApplications);
      }

      getFeatures(resourceName);
    }
  };

  const cloneVersion = async (name, kind, version) => {
    const isAddingVersion = true;

    let featuresToClone = featureEntities.filter((feature) => (feature.version === cloneFromVersion));

    featuresToClone = featuresToClone[0].features.map((feature) => (feature.name));

    addNewResource(name, kind, version, isAddingVersion, featuresToClone);
  };

  const removeFeature = async (featureName, version) => {
    try {
      const {
        data: { id: resourceId },
      } = await gerResourceSortedByKindByNameAndVersion(tabValue, resourceName, version);

      let {
        data: { _embedded: { featureEntities: featureEntitiesForThisResource } },
      } = await getFeatureEntitiesFindByResourceId(resourceId);

      featureEntitiesForThisResource = featureEntitiesForThisResource.filter((feature) => (feature.name === featureName));
      const { id: featureId } = featureEntitiesForThisResource[0];

      await deleteDataByPathnameAndId(FEATURE_ENTITIES_PATH, featureId);
    } catch (err) {
      throw err.message;
    } finally {
      getFeatures(resourceName);
    }
  };

  /**
   * @function useEffect, fetch data for users, group members, group ids and feature names
   */
  useEffect(() => {
    getUsersEntities();
    getGroupMembers();
    getGroupId();
    // eslint-disable-next-line
  }, []);

  /**
   * @function useEffect, reset feature entities when we switch between tabs
   */
  useEffect(() => {
    setFeatureEntities([]);
    // eslint-disable-next-line
  }, [tabValue]);
  /**
   * @function useEffect, get(fetch) resources('PROJECTS' or 'APPLICATIONS')
   * when group name is changed(when we press on particular group in the list of groups)
   */
  useEffect(() => {
    getResources();
    // eslint-disable-next-line
  }, [groupInputName]);
  /**
   * @function useEffect, when we filtering users(ROWS_USERS array length is changed) re-render the application with the new filtered data
   */
  useEffect(() => {
    const filteredAllUsers = ALL_ROWS_USERS.map((item) => ({
      ...item,
      checked: ROWS_USERS.filter((assignedUser) => (item.username === assignedUser.username)).length > 0,
    }));

    setAllRowUsers(filteredAllUsers);
    setFilteredUsers(ROWS_USERS);
    // eslint-disable-next-line
  }, [ROWS_USERS.length]);

  return (
    <div className={classes.root}>
      <Header
        title={`Group: ${groupInputName}`}
        name={currentUser}
      />
      <SideNav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <AppBar position="static" color="default" className={classes.tabs}>
                  <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {TAB_NAMES.map((tab) => (<Tab key={tab} value={tab} label={tab} />))}
                  </Tabs>
                </AppBar>
                {tabValue === t('information') && (
                  <Grid container>
                    <Grid item xs style={{ padding: '10px' }}>
                      <Typography component="p" variant="body1">
                        {t('moreInfo')}
                        :
                      </Typography>
                      <TextField
                        label={t('groupName')}
                        value={groupInputName}
                        margin="normal"
                        onChange={handleGroupChange}
                        disabled
                      />
                    </Grid>
                  </Grid>
                )}
                {tabValue === t('projects') && (
                  isLoadingProjectsAndApplications ? <Loader />
                    : (
                      <>
                        <Grid container display="flex" alignItems="center">
                          <Box display="flex" justifyContent="flex-end" flex="1">
                            <Fab
                              color="primary"
                              aria-label="Add"
                              className={classes.fab}
                              onClick={() => { toggleModalVisibility(true); }}
                              size="small"
                            >
                              <Add />
                            </Fab>
                          </Box>
                        </Grid>
                        <Grid container>
                          <Grid item xs style={{ padding: '10px' }}>
                            <Typography>
                              {t('Projects')}
                              :
                            </Typography>
                            {ROWS_PROJECTS.map((project) => (
                              <ExpansionPanel
                                key={project.name}
                                expanded={expanded === project.name}
                                onChange={handleChange(project.name)}
                              >
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                >
                                  <Typography className={classes.heading}>{project.name}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Grid container direction="row">
                                    <Grid item xs>
                                      <Box border={1} borderRadius={5} borderColor="#ccc" m={1}>
                                        <List
                                          component="ul"
                                          aria-labelledby="list-subheader"
                                          subheader={(
                                            <li>
                                              <Grid container>
                                                <Grid item md={4}>
                                                  <ListSubheader component="div" id="list-subheader">
                                                    {t('Versions')}
                                                    :
                                                  </ListSubheader>
                                                </Grid>
                                                <Grid item md={8}>
                                                  <ListSubheader component="div" id="list-subheader-permissions">
                                                    {t('Permissions')}
                                                    :
                                                  </ListSubheader>
                                                </Grid>
                                              </Grid>
                                            </li>
                                          )}
                                        >
                                          {project.data.map((versionObject) => (
                                            <Box key={`Axilion License Server-${versionObject.version}`}>
                                              <ListItem className={classes.removeOnHover}>
                                                <Grid container spacing={3}>
                                                  <Grid item md={4}>
                                                    <Typography>{versionObject.version}</Typography>
                                                  </Grid>
                                                  <Grid item md={6}>
                                                    <Typography>{versionObject.permissions.join(', ')}</Typography>
                                                  </Grid>
                                                  <Grid item md={2}>
                                                    <span>
                                                      <Tooltip title={t('CloneVersion')} placement="top">
                                                        <LibraryAddOutlined
                                                          fontSize="small"
                                                          color="primary"
                                                          onClick={() => {
                                                            setVersionForResource(project.name);
                                                            setCloneFromVersion(versionObject.version);
                                                            setIsCloning(true);
                                                            setOpenNewVersionModal(true);
                                                          }}
                                                        />
                                                      </Tooltip>
                                                      <Tooltip title={t('DeleteVersion')} placement="top">
                                                        <CloseRounded
                                                          fontSize="small"
                                                          color="primary"
                                                          onClick={() => {
                                                            removeVersion(versionObject.version);
                                                            setCurrentRowForDeletion(project);
                                                          }}
                                                        />
                                                      </Tooltip>
                                                    </span>
                                                  </Grid>
                                                </Grid>
                                              </ListItem>
                                              <Divider />
                                            </Box>
                                          ))}
                                          <Box display="flex" flex="1">
                                            <Button
                                              color="primary"
                                              aria-label="Add"
                                              onClick={() => {
                                                setVersionForResource(project.name);
                                                setIsCloning(false);
                                                setOpenNewVersionModal(true);
                                              }}
                                              size="small"
                                              style={{ marginLeft: 'auto', marginRight: 15, marginTop: 15 }}
                                              variant="outlined"
                                            >
                                              {t('AddVersion')}
                                            </Button>
                                          </Box>
                                        </List>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </ExpansionPanelDetails>
                                <Box display="flex" flex="1">
                                  <Tooltip title={t('DeleteProject')} placement="top">
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      onClick={() => {
                                        setIsDeleteDialogOpen(true);
                                        setCurrentRowForDeletion(project);
                                      }}
                                      style={{ marginLeft: 'auto', marginRight: 10 }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </ExpansionPanel>
                            ))}
                          </Grid>
                          <Grid item xs style={{ padding: '10px' }}>
                            <Typography>
                              {t('Features')}
                              :
                            </Typography>
                            {featureEntities.length > 0 && (
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>{t('Versions')}</TableCell>
                                    <TableCell>{t('Features')}</TableCell>
                                  </TableRow>
                                </TableHead>
                                {featureEntities.map((feature) => (
                                  <TableBody key={feature.version}>
                                    <TableRow>
                                      <TableCell>{feature.version}</TableCell>
                                      <TableCell style={{ whiteSpace: 'pre' }}>
                                        <span
                                          style={{ whiteSpace: 'pre' }}
                                        >
                                          {feature.features.map((f, i) => (
                                            <div key={i.toString()} className={classes.removeOnHover}>
                                              {f.name}
                                              <span>
                                                <Tooltip title={t('DeleteFeature')} placement="top">
                                                  <CloseRounded
                                                    fontSize="small"
                                                    color="primary"
                                                    onClick={() => {
                                                      removeFeature(f.name, feature.version);
                                                    }}
                                                  />
                                                </Tooltip>
                                              </span>
                                            </div>
                                          ))}
                                        </span>
                                        <Box display="flex" flex="1">
                                          <Button
                                            color="primary"
                                            aria-label="Add"
                                            onClick={() => {
                                              setVersionToEdit(feature.version);
                                              setOpenNewFeatureModal(true);
                                            }}
                                            size="small"
                                            style={{ marginLeft: 'auto', marginTop: 15 }}
                                            variant="outlined"
                                          >
                                            {t('AddFeature')}
                                          </Button>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                ))}
                              </Table>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )
                )}
                {tabValue === t('applications') && (
                  isLoadingProjectsAndApplications ? <Loader />
                    : (
                      <>
                        <Grid container display="flex" alignItems="center">
                          <Box display="flex" justifyContent="flex-end" flex="1">
                            <Fab
                              color="primary"
                              aria-label="Add"
                              className={classes.fab}
                              onClick={() => { toggleModalVisibility(true); }}
                              size="small"
                            >
                              <Add />
                            </Fab>
                          </Box>
                        </Grid>
                        <Grid container>
                          <Grid item xs style={{ padding: '10px' }}>
                            <Typography>
                              {t('Applications')}
                              :
                            </Typography>
                            {ROWS_APPLICATIONS.map((application) => (
                              <ExpansionPanel
                                key={application.name}
                                expanded={expanded === application.name}
                                onChange={handleChange(application.name)}
                              >
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                >
                                  <Typography className={classes.heading}>{application.name}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Grid container direction="row">
                                    <Grid item xs>
                                      <Box border={1} borderRadius={5} borderColor="#ccc">
                                        <List
                                          component="ul"
                                          aria-labelledby="list-subheader"
                                          subheader={(
                                            <li>
                                              <Grid container>
                                                <Grid item md={4}>
                                                  <ListSubheader component="div" id="list-subheader">
                                                    {t('Versions')}
                                                    :
                                                  </ListSubheader>
                                                </Grid>
                                                <Grid item md={8}>
                                                  <ListSubheader component="div" id="list-subheader-permissions">
                                                    {t('Permissions')}
                                                    :
                                                  </ListSubheader>
                                                </Grid>
                                              </Grid>
                                            </li>
                                          )}
                                        >
                                          {application.data.map((versionObject) => (
                                            <Box key={`${application.name}-${versionObject.version}`}>
                                              <ListItem className={classes.removeOnHover}>
                                                <Grid container spacing={3}>
                                                  <Grid item md={4}>
                                                    <Typography>{versionObject.version}</Typography>
                                                  </Grid>
                                                  <Grid item md={6}>
                                                    <Typography>{versionObject.permissions.join(', ')}</Typography>
                                                  </Grid>
                                                  <Grid item md={2}>
                                                    <span>
                                                      <Tooltip title={t('CloneVersion')} placement="top">
                                                        <LibraryAddOutlined
                                                          fontSize="small"
                                                          color="primary"
                                                          onClick={() => {
                                                            setVersionForResource(application.name);
                                                            setCloneFromVersion(versionObject.version);
                                                            setIsCloning(true);
                                                            setOpenNewVersionModal(true);
                                                          }}
                                                        />
                                                      </Tooltip>
                                                      <Tooltip title={t('DeleteVersion')} placement="top">
                                                        <CloseRounded
                                                          fontSize="small"
                                                          color="primary"
                                                          onClick={() => {
                                                            removeVersion(versionObject.version);
                                                            setCurrentRowForDeletion(application);
                                                          }}
                                                        />
                                                      </Tooltip>
                                                    </span>
                                                  </Grid>
                                                </Grid>
                                              </ListItem>
                                              <Divider />
                                            </Box>
                                          ))}
                                          <Box display="flex" flex="1">
                                            <Button
                                              color="primary"
                                              aria-label="Add"
                                              onClick={() => {
                                                setVersionForResource(application.name);
                                                setIsCloning(false);
                                                setOpenNewVersionModal(true);
                                              }}
                                              size="small"
                                              style={{ marginLeft: 'auto', marginRight: 15, marginTop: 15 }}
                                              variant="outlined"
                                            >
                                              {t('AddVersion')}
                                            </Button>
                                          </Box>
                                        </List>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </ExpansionPanelDetails>
                                <Box display="flex" flex="1">
                                  <Tooltip title={t('DeleteApplication')} placement="top">
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      onClick={() => {
                                        setIsDeleteDialogOpen(true);
                                        setCurrentRowForDeletion(application);
                                      }}
                                      style={{ marginLeft: 'auto', marginRight: 10 }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </ExpansionPanel>
                            ))}
                          </Grid>
                          <Grid item xs style={{ padding: '10px' }}>
                            <Typography>
                              {t('Features')}
                              :
                            </Typography>
                            {featureEntities.length > 0 && (
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>{t('Versions')}</TableCell>
                                    <TableCell>{t('Features')}</TableCell>
                                  </TableRow>
                                </TableHead>
                                {featureEntities.map((row) => (
                                  <TableBody key={row.version}>
                                    <TableRow>
                                      <TableCell>{row.version}</TableCell>
                                      <TableCell>
                                        <span
                                          style={{ whiteSpace: 'pre' }}
                                        >
                                          {row.features.map((f, i) => (
                                            <div key={i.toString()} className={classes.removeOnHover}>
                                              {f.name}
                                              <span>
                                                <Tooltip title={t('DeleteFeature')} placement="top">
                                                  <CloseRounded
                                                    fontSize="small"
                                                    color="primary"
                                                    onClick={() => {
                                                      removeFeature(f.name, row.version);
                                                    }}
                                                  />
                                                </Tooltip>
                                              </span>
                                            </div>
                                          ))}
                                        </span>
                                        <Box display="flex" flex="1">
                                          <Button
                                            color="primary"
                                            aria-label="Add"
                                            onClick={() => {
                                              setVersionToEdit(row.version);
                                              setOpenNewFeatureModal(true);
                                            }}
                                            size="small"
                                            style={{ marginLeft: 'auto' }}
                                            variant="outlined"
                                          >
                                            {t('AddFeature')}
                                          </Button>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                ))}
                              </Table>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )
                )}
                {tabValue === t('members') && (
                  isLoadingGroupMembers ? <Loader />
                    : (
                      <Grid container direction="column" style={{ paddingTop: '10px' }}>
                        <Grid item xs>
                          <Grid container display="flex" alignItems="center">
                            <InputSearch
                              handleSearch={handleSearch}
                            />
                            <Box display="flex" justifyContent="flex-end" flex="1">
                              <Fab
                                color="primary"
                                aria-label="Edit"
                                className={classes.fab}
                                onClick={() => { toggleModalVisibility(true); }}
                                size="small"
                              >
                                <Edit />
                              </Fab>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs>
                          <UsersTable
                            headers={[
                              t('tableHeaders.username'),
                              t('tableHeaders.name'),
                              t('tableHeaders.email'),
                              t('tableHeaders.company'),
                              t('tableHeaders.registered'),
                              t('tableHeaders.modifiedAt'),
                              t('tableHeaders.eula'),
                              t('tableHeaders.active'),
                            ]}
                            users={filteredUsers}
                            currentUser={currentUser}
                            handleIsActive={handleIsActive}
                          />
                        </Grid>
                      </Grid>
                    )
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
      {tabValue === t('members') && (
        <DialogWindow
          title={t('assignUsersToGroup')}
          openModal={openModal}
          toggleModalVisibility={toggleModalVisibility}
          okAction={() => { toggleModalVisibility(false); }}
          hasCancelButton={false}
        >
          <List>
            {ALL_ROWS_USERS.map((row, index) => (
              <ListItem
                key={row.username}
                role={undefined}
                dense
                button
                onClick={() => { handleCheckboxToggle(index); }}
              >
                <ListItemText
                  id={row.username}
                  primary={`${row.username} (${row.customerName ? row.customerName : 'N/A'})`}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="start"
                    checked={row.checked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${row.username}` }}
                    color="primary"
                    onClick={() => { handleCheckboxToggle(index); }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogWindow>
      )}
      {(tabValue === t('projects') || tabValue === t('applications')) && (
        <DialogWindow
          title={
            t('dialogWindows.createNewResourceType',
              { resourceType: tabValue === t('projects') ? PROJECT_VALUE.toLowerCase() : APPLICATION_VALUE.toLowerCase() })
          }
          openModal={openModal}
          toggleModalVisibility={toggleModalVisibility}
          okAction={() => {
            const kind = tabValue === t('projects') ? 'PROJECT' : 'APPLICATION';

            addNewResource(formState.resourcename, kind, formState.resourceversion);
          }}
        >
          <>
            <TextField
              key="resourcename"
              margin="dense"
              id="resourcename"
              name="resourcename"
              label={t('dialogWindows.name')}
              type="text"
              fullWidth
              onChange={handleOnChange}
            />
            <TextField
              key="resourceversion"
              margin="dense"
              id="resourceversion"
              name="resourceversion"
              label={t('dialogWindows.version')}
              type="text"
              fullWidth
              onChange={handleOnChange}
            />
            <FormControl required error={error} component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">
                {t('Permissions')}
                :
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  // eslint-disable-next-line max-len
                  control={<Checkbox color="primary" checked={readResource} onChange={handleChangePermission('readResource')} value="readResource" />}
                  label={t('Read')}
                />
                <FormControlLabel
                  // eslint-disable-next-line max-len
                  control={<Checkbox color="primary" checked={writeResource} onChange={handleChangePermission('writeResource')} value="writeResource" />}
                  label={t('Write')}
                />
                <FormControlLabel
                  // eslint-disable-next-line max-len
                  control={<Checkbox color="primary" checked={createResource} onChange={handleChangePermission('createResource')} value="createResource" />}
                  label={t('Create')}
                />
                <FormControlLabel
                  // eslint-disable-next-line max-len
                  control={<Checkbox color="primary" checked={deleteResource} onChange={handleChangePermission('deleteResource')} value="deleteResource" />}
                  label={t('Delete')}
                />
              </FormGroup>
              <FormHelperText>{t('dialogWindows.pickAtLeastOne')}</FormHelperText>
            </FormControl>
          </>
        </DialogWindow>
      )}
      <DialogWindow
        title={
          isCloning
            ? t('dialogWindows.cloneNewVersion', {
              resourceType: tabValue === t('projects') ? PROJECT_VALUE.toLowerCase() : APPLICATION_VALUE.toLowerCase(),
              resourceName: versionForResource,
            })
            : t('dialogWindows.createNewVersion', {
              resourceType: tabValue === t('projects') ? PROJECT_VALUE.toLowerCase() : APPLICATION_VALUE.toLowerCase(),
              resourceName: versionForResource,
            })
        }
        openModal={openNewVersionModal}
        toggleModalVisibility={toggleModalVisibilityVersion}
        okAction={() => {
          const kind = tabValue === t('projects') ? 'PROJECT' : 'APPLICATION';
          const isAddingVersion = true;

          if (isCloning) {
            cloneVersion(versionForResource, kind, formState.resourceversion);
          } else {
            addNewResource(versionForResource, kind, formState.resourceversion, isAddingVersion);
          }
        }}
      >
        <>
          <TextField
            key="resourceversion"
            margin="dense"
            id="resourceversion"
            name="resourceversion"
            label={t('dialogWindows.version')}
            type="text"
            fullWidth
            onChange={handleOnChange}
          />
          <FormControl required error={error} component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              {t('Permissions')}
              :
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                // eslint-disable-next-line max-len
                control={<Checkbox color="primary" checked={readResource} onChange={handleChangePermission('readResource')} value="readResource" />}
                label={t('Read')}
              />
              <FormControlLabel
                // eslint-disable-next-line max-len
                control={<Checkbox color="primary" checked={writeResource} onChange={handleChangePermission('writeResource')} value="writeResource" />}
                label={t('Write')}
              />
              <FormControlLabel
                // eslint-disable-next-line max-len
                control={<Checkbox color="primary" checked={createResource} onChange={handleChangePermission('createResource')} value="createResource" />}
                label={t('Create')}
              />
              <FormControlLabel
                // eslint-disable-next-line max-len
                control={<Checkbox color="primary" checked={deleteResource} onChange={handleChangePermission('deleteResource')} value="deleteResource" />}
                label={t('Delete')}
              />
            </FormGroup>
            <FormHelperText>{t('dialogWindows.pickAtLeastOne')}</FormHelperText>
          </FormControl>
        </>
      </DialogWindow>
      <DialogWindow
        title={t('dialogWindows.createNewFeature', {
          resourceType: tabValue === t('applications') ? APPLICATION_VALUE.toLowerCase() : PROJECT_VALUE.toLowerCase(),
          resourceName,
          versionToEdit,
        })}
        openModal={openNewFeatureModal}
        toggleModalVisibility={() => {
          setOpenNewFeatureModal(false);
          setIsFeatureForAllVersions(false);
        }}
        okAction={() => {
          addNewFeature(formState.featurename.trim());
          setIsFeatureForAllVersions(false);
        }}
      >
        <>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="simple-select-outlined-label">{t('dialogWindows.featureName')}</InputLabel>
            <Select
              value={formState.featurename}
              onChange={handleOnChange}
              labelWidth={100}
              id="featurename"
              name="featurename"
            >
              {FEATURES.map((feature) => (
                <MenuItem value={feature} key={feature}>{feature}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                checked={isFeatureForAllVersions}
                onChange={() => setIsFeatureForAllVersions((oldValue) => !oldValue)}
              />
            )}
            label={t('addNewFeatureLabel')}
          />
        </>
      </DialogWindow>
      <DialogWindow
        openModal={isDeleteDialogOpen}
        title={t('dialogWindows.deleteConfirmMessage', {
          resourceType: tabValue === t('applications') ? APPLICATION_VALUE.toLowerCase() : PROJECT_VALUE.toLowerCase(),
          resourceName,
        })}
        toggleModalVisibility={toggleModalVisibilityDelete}
        okAction={handleDeleteResource}
        hasCancelButton
        handleOnChange={handleCancelDeleteWindow}
      >
        <Typography>
          {t('dialogWindows.warningConfirmMessage', {
            resourceType: tabValue === t('applications') ? APPLICATION_VALUE.toLowerCase() : PROJECT_VALUE.toLowerCase(),
            resourceName,
          })}
        </Typography>
      </DialogWindow>
    </div>

  );
};

export default Group;
