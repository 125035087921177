import axios from 'axios';

const requester = (method) => ((url, data = {}, headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}, options) => axios({
  method,
  url,
  data,
  withCredentials: true,
  validateStatus: false,
  headers,
  ...options,
})
);

export const get = requester('get');

export const requesterAll = () => ((urls, data, headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}, options) => axios.all(urls.map((url) => get(url, {}, headers, options)))
);

export const post = requester('post');

export const put = requester('put');

export const del = requester('delete');

export const patch = requester('patch');

export const all = requesterAll();
