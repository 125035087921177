import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import propTypes from 'prop-types';

/**
 * @file Loader component. Used across the application for showing spinner when content is loaded
 * @param {number} size the size of the spinner in pixels, default value is 60 pixels
 * @param {string} alignItems with values 'flex-start', 'flex-end', 'center', 'justify-around', 'justify-between' for centering the loader in its own Grid container
 * @param {number} padding the padding in the Grid container, default value is 40px
 */
const Loader = ({ size, alignItems, containerPadding }) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems={alignItems}
    justify="center"
    style={{ padding: containerPadding }}
  >
    <Grid item xs={3}>
      <CircularProgress size={size} thickness={4.2} color="primary" />
    </Grid>
  </Grid>
);

Loader.propTypes = {
  size: propTypes.number,
  alignItems: propTypes.string,
  containerPadding: propTypes.number,
};

Loader.defaultProps = {
  size: 60,
  alignItems: 'center',
  containerPadding: 40,
};

export default Loader;
