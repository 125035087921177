import React from 'react';
import propTypes from 'prop-types';
import {
  ExpansionPanel, ExpansionPanelSummary, Typography, Table, TableHead, TableRow, TableCell,
  ExpansionPanelDetails, TableBody, Box, Button, LinearProgress,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ProjectsListStyles from './ProjectsList.styles';

const useStyles = makeStyles(() => (ProjectsListStyles()));

const ProjectsList = ({
  projects,
  hasProjects,
  expanded,
  handleChange,
  intersections,
  setIntersectionDetailsForProject,
  toggleModalVisibility,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    hasProjects ? (
      projects.map((project) => (
        <ExpansionPanel
          key={project.name}
          expanded={expanded === project.name}
          onChange={handleChange(project.name, project.id, 'project')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>{project.name}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {project.versions.length > 1 ? `${t('tableHeaders.versions')}:` : `${t('tableHeaders.version')}:`}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {project.versions.map((version, i) => (
                  <TableRow key={i.toString()}>
                    <TableCell>{`v 1.5-SNAPSHOT`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ExpansionPanelDetails>
          <Box style={{ padding: '8px 24px 24px' }}>
            <Typography component="p" variant="body1">
              {t('Intersections')}
              :
              {' '}
              {intersections.usedIntersections}
              /
              {intersections.totalIntersections}
            </Typography>
            <LinearProgress
              color="secondary"
              variant="determinate"
              value={
                intersections.totalIntersections === 0 ? 0
                  : (intersections.usedIntersections / intersections.totalIntersections) * 100
              }
              style={{ marginTop: '15px', padding: '5px', borderRadius: '3px' }}
            />
          </Box>
          <Box display="flex" flex="1">
            <Button
              color="primary"
              aria-label="Details"
              onClick={() => {
                setIntersectionDetailsForProject(project.name);
                toggleModalVisibility(true);
              }}
              size="medium"
              style={{ margin: 15, marginLeft: 'auto' }}
              variant="outlined"
            >
              {t('Details')}
            </Button>
          </Box>
        </ExpansionPanel>
      ))
    )
      : (
        <Typography>
          {t('noProjects')}
        </Typography>
      )
  );
};

ProjectsList.propTypes = {
  projects: propTypes.array.isRequired,
  hasProjects: propTypes.bool.isRequired,
  expanded: propTypes.string.isRequired,
  handleChange: propTypes.func.isRequired,
  intersections: propTypes.object.isRequired,
  setIntersectionDetailsForProject: propTypes.func.isRequired,
  toggleModalVisibility: propTypes.func.isRequired,
};

export default ProjectsList;
