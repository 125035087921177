import {
  SET_CURRENT_USER,
  SET_CURRENT_USER_ROLE,
} from '../actions/actionTypes';

const initialState = {
  currentUser: '',
  createdDate: '',
  modifiedDate: '',
  isAdmin: false,
};

const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, ...action.user, currentUser: action.user.username };
    case SET_CURRENT_USER_ROLE:
      return { ...state, isAdmin: action.isAdmin };
    default:
      return state;
  }
};

export default currentUser;
