import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar } from '@material-ui/core';
import HeaderLogoStyles from './HeaderLogo.styles';
import logo from '../assets/logo.svg';

const useStyles = makeStyles((theme) => (HeaderLogoStyles(theme)));

const HeaderLogo = () => {
  const classes = useStyles();

  return (
    <AppBar
      color="secondary"
      position="static"
      className={classes.appBar}
    >
      <img src={logo} alt="axilion-logo" className={classes.logo} />
    </AppBar>
  );
};

export default HeaderLogo;
