import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSideNav } from '../../store/actions';
import HeaderStyles from './Header.styles';

const useStyles = makeStyles((theme) => (HeaderStyles(theme)));

const Header = ({ title, subtitle, name }) => {
  const classes = useStyles();
  const { isSideNavOpen } = useSelector((state) => state.toggleSideNav);
  const dispatch = useDispatch();

  return (
    <AppBar
      color="secondary"
      position="absolute"
      className={clsx(classes.appBar, isSideNavOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Open drawer"
          onClick={() => { dispatch(toggleSideNav(!isSideNavOpen)); }}
          className={clsx(classes.menuButton, isSideNavOpen && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="span" variant="h6" color="inherit" noWrap className={classes.title}>
          {title}
          <Typography component="h6" variant="subtitle2" color="inherit">{subtitle}</Typography>
        </Typography>
        <Link to="/profile" style={{ color: '#fff', textDecoration: 'none' }}>
          <IconButton color="inherit">
            <AccountCircleRounded />
          </IconButton>
        </Link>
        <Link to="/profile" style={{ color: '#fff', textDecoration: 'none' }}>
          <Typography component="h2" variant="subtitle1" color="inherit" noWrap>
            {name}
          </Typography>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  title: propTypes.string.isRequired,
  subtitle: propTypes.string,
  name: propTypes.string.isRequired,
};

Header.defaultProps = {
  subtitle: null,
};

export default Header;
