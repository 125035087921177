import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { useTimer } from 'react-timer-hook';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  timer: {
    color: theme.palette.primary.main,
  },
}));

const CountDownTimer = ({ validityRangeInSeconds, onExpire }) => {
  const classes = useStyles();
  const {
    seconds,
    minutes,
    hours,
    days,
    restart,
  } = useTimer({
    expiryTimestamp: new Date().setSeconds(new Date().getSeconds() + validityRangeInSeconds),
    onExpire,
  });

  useEffect(() => {
    restart(new Date().setSeconds(new Date().getSeconds() + validityRangeInSeconds));
    // eslint-disable-next-line
  }, [validityRangeInSeconds]);

  return (
    <span className={classes.timer}>
      {`${days}d  `}
      {hours > 9 ? `${hours}h  ` : `0${hours}h  `}
      {minutes > 9 ? `${minutes}m  ` : `0${minutes}m  `}
      {seconds > 9 ? `${seconds}s` : `0${seconds}s`}
    </span>
  );
};

CountDownTimer.propTypes = {
  validityRangeInSeconds: propTypes.number.isRequired,
  onExpire: propTypes.func.isRequired,
};

export default CountDownTimer;
