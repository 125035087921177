import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloudDownload from '@material-ui/icons/CloudDownload';
import {
  AppBar, Typography, Tabs, Tab, Paper, Grid, Container, Box,
  List, ListSubheader, ListItem,
  ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,
  Table, TableRow, TableCell, TableHead, TableBody, Link,
  ListItemSecondaryAction, IconButton, ListItemText, LinearProgress,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Header,
  SideNav,
  Loader,
  InputSearch,
} from '../../common';

import ProfileStyles from './Profile.styles';
import {
  getAllocationsCountByProjectName,
  getUserCustomers,
} from '../../api/profile-service';
import {
  getUserByUsername,
  getDataForPathName,
  getDataByProvidedArrayOfUrls,
} from '../../api/common-service';
import { getProjectsForUser } from '../../api/complex-fetching-data-service';
import {
  ALLOCATION_ENTITIES_PATH,
  EVENTS_ENTITIES_PATH,
  dataNamespaceUrl,
  helpDeskPortalUrl,
} from '../../constants/url-related';

const useStyles = makeStyles((theme) => (ProfileStyles(theme)));

const Profile = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser, createdDate, modifiedDate, isAdmin } = useSelector((state) => state.currentUserData);

  const TAB_NAMES = [t('information'), t('projects'), t('applications'), t('intersections'), isAdmin ? t('history') : null]
    .filter((tabName) => tabName !== null);
  const [value, setValue] = useState(TAB_NAMES[0]);
  const [user, setUser] = useState({
    username: '',
    name: '',
    id: '0',
    createdDate: '2020-02-02',
    modifiedDate: '2020-02-02',
  });
  const [ROWS_PROJECTS, setProjects] = useState([]);
  const [ROWS_APPLICATIONS, setApplications] = useState([]);
  const [ROWS_INTERSECTIONS, setIntersections] = useState([]);
  const [filteredIntersections, setFilteredIntersections] = useState([]);
  const [ROWS_HISTORY, setRowsHistory] = useState([]);
  const [totalIntersections, setTotalIntersections] = useState(0);
  const [usedIntersections, setUsedIntersections] = useState(0);
  const [isLoadingProjectsAndApplications, setIsLoadingProjectsAndApplications] = useState(false);
  const [isLoadingIntersections, setIsLoadingIntersections] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const [expanded, setExpanded] = useState(false);

  const getAllocationEntities = async (id, panel, isExpanded) => {
    let total;

    let used;

    try {
      const allocation = await getDataForPathName(ALLOCATION_ENTITIES_PATH);
      const { data: { _embedded: { allocationEntities } } } = allocation;
      const filteredByName = allocationEntities.filter((item) => (item.projectName === panel));

      total = filteredByName.length > 0 ? filteredByName[0].intersections : 0;

      const options = { params: { projectName: panel } };
      const { data } = await getAllocationsCountByProjectName(options);

      used = data;
    } catch (err) {
      throw err.message;
    } finally {
      setTotalIntersections(total);
      setUsedIntersections(used);
      setExpanded(isExpanded ? panel : false);
    }
  };

  const handleChange = (panel) => ((event, isExpanded) => {
    getAllocationEntities(undefined, panel, isExpanded);
  });
  const handleSearch = ({ target: { value: v } }) => {
    if (v === '') {
      setFilteredIntersections(ROWS_INTERSECTIONS);

      return;
    }
    const filteredArrIntersections = ROWS_INTERSECTIONS.map(
      (item) => item.filter((inner) => (inner.identifier.toLowerCase().includes(v.toLowerCase())
        || inner.projectName.toLowerCase().includes(v.toLowerCase()))),
    );

    setFilteredIntersections(filteredArrIntersections);
  };

  const getUser = async () => {
    try {
      const { data: { _embedded: { customerEntities: userData } } } = await getUserCustomers(currentUser);

      setUser({
        id: userData[0].id.toString(),
        username: currentUser,
        name: `${userData[0].firstName} ${userData[0].lastName}`,
        email: `${userData[0].email}`,
        createdDate: `${createdDate}`,
        modifiedDate: `${modifiedDate}`,
      });
    } catch (err) {
      throw err.message;
    }
  };

  const getIntersections = async () => {
    let intersections;

    setIsLoadingIntersections(true);
    try {
      const getIntersectionsUrls = ROWS_PROJECTS.map((project) => (
        // eslint-disable-next-line max-len
        `${dataNamespaceUrl}/intersectionEntities/search/findByAllocationProjectName?projectName=Axilion License Server`));
      const res = await getDataByProvidedArrayOfUrls(getIntersectionsUrls);

      intersections = res.map((intersectionEntity, i) => {
        const obj = intersectionEntity.data._embedded.intersectionEntities.map((intersection) => {
          const o = intersection;

          o.projectName = ROWS_PROJECTS[i].name;

          return o;
        });

        return obj;
      });
    } catch (err) {
      throw err.message;
    } finally {
      setIntersections(intersections);
      setFilteredIntersections(intersections);
      setIsLoadingIntersections(false);
    }
  };

  const getHistory = async () => {
    setIsLoadingHistory(true);
    try {
      const { data: { _embedded: { eventsEntities } } } = await getDataForPathName(EVENTS_ENTITIES_PATH);

      setRowsHistory(eventsEntities);
    } catch (err) {
      throw err.message;
    }
    setIsLoadingHistory(false);
  };

  const downloadApp = (application, version) => {
    window.location.href = `/download/APPLICATION/${application}/1.5-SNAPSHOT`;
  };

  const downloadProject = (project, version) => {
    window.location.href = `/download/PROJECT/${project}/1.5-SNAPSHOT`;
  };

  const getProjectsAndApplicationsForCurrUser = async () => {
    setIsLoadingProjectsAndApplications(true);
    const result = await getProjectsForUser(user.username, getUserByUsername);

    setProjects(result.projects);
    setApplications(result.applications);
    setIsLoadingProjectsAndApplications(false);
  };

  useEffect(() => {
    if (currentUser) {
      getUser();
      if (isAdmin) {
        getHistory();
      }
    }
    // eslint-disable-next-line
  }, [currentUser, isAdmin]);

  useEffect(() => {
    if (user.username.length > 0) {
      getProjectsAndApplicationsForCurrUser();
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (ROWS_PROJECTS.length > 0) {
      getIntersections();
    }
    // eslint-disable-next-line
  }, [ROWS_PROJECTS]);


  return (
    <div className={classes.root}>
      <Header
        title="Profile"
        name={user.username}
      />
      <SideNav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <AppBar position="static" color="default" className={classes.tabs}>
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {TAB_NAMES.map((tab) => (<Tab key={tab} value={tab} label={tab} />))}
                  </Tabs>
                </AppBar>
                <Grid container justify="flex-end">
                  <Link href={helpDeskPortalUrl} className={classes.helpDeskLink} target="_blank">
                    {t('AxilionServiceDesk')}
                  </Link>
                </Grid>
                {value === t('information') && (
                  <Grid container direction="column">
                    <Grid item xs>
                      <List
                        component="ul"
                        aria-labelledby="list-subheader"
                        subheader={(
                          <li>
                            <ListSubheader component="div" id="list-subheader">
                              {t('profileInfo')}
                            </ListSubheader>
                          </li>
                        )}
                      >
                        <ListItem>
                          <Typography component="p" variant="subtitle1">
                            {t('Name')}
                            :
                            {' '}
                            {user.name}
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography component="p" variant="subtitle1">
                            {t('Username')}
                            :
                            {' '}
                            {user.username}
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography component="p" variant="subtitle1">
                            {t('Email')}
                            :
                            {' '}
                            {user.email}
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography component="p" variant="subtitle1">
                            {t('Signed')}
                            :
                            {' '}
                            {format(new Date(user.createdDate), 'yyyy/MM/dd (hh:mm a)')}
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography component="p" variant="subtitle1">
                            Last modified at:
                            {' '}
                            {format(new Date(user.modifiedDate), 'yyyy/MM/dd (hh:mm a)')}
                          </Typography>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                )}
                {value === t('projects') && (
                  isLoadingProjectsAndApplications ? <Loader />
                    : (
                      <Grid container>
                        <Grid item xs style={{ padding: '10px' }}>
                          {ROWS_PROJECTS.map((project) => (
                            <ExpansionPanel
                              key={project.name}
                              expanded={expanded === project.name}
                              onChange={handleChange(project.name, project.id, 'project')}
                            >
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <Typography className={classes.heading}>{project.name}</Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Box border={1} borderRadius={5} borderColor="#ccc" flex="1">
                                  <List
                                    component="ul"
                                    aria-labelledby="list-subheader"
                                    subheader={(
                                      <li>
                                        <ListSubheader component="div" id="list-subheader">
                                          {t('Versions')}
                                          :
                                        </ListSubheader>
                                      </li>
                                    )}
                                  >
                                    {project.versions.map((version, i) => (
                                      <ListItem key={i.toString()}>
                                        <ListItemText
                                          primary={`v1.5-SNAPSHOT`}
                                        />
                                        <ListItemSecondaryAction>
                                          <IconButton
                                            edge="end"
                                            aria-label="download-project"
                                            onClick={() => { downloadProject(project.name, version); }}
                                          >
                                            <CloudDownload />
                                          </IconButton>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    ))}
                                    <ListItem>
                                      <Typography component="p" variant="body1">
                                        {t('Intersections')}
                                        :
                                        {' '}
                                        {usedIntersections}
                                        /
                                        {totalIntersections}
                                      </Typography>
                                    </ListItem>
                                  </List>
                                  <LinearProgress
                                    color="secondary"
                                    variant="determinate"
                                    value={
                                      totalIntersections === 0 ? 0
                                        : (usedIntersections / totalIntersections) * 100
                                    }
                                    style={{ margin: '15px', padding: '5px', borderRadius: '3px' }}
                                  />
                                </Box>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          ))}
                        </Grid>
                      </Grid>
                    )
                )}
                {value === t('applications') && (
                  isLoadingProjectsAndApplications ? <Loader />
                    : (
                      <Grid container>
                        <Grid item xs style={{ padding: '10px' }}>
                          {ROWS_APPLICATIONS.map((application) => (
                            <ExpansionPanel
                              key={application.name}
                              expanded={expanded === application.name}
                              onChange={handleChange(application.name, application.id, 'application')}
                            >
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <Typography className={classes.heading}>{application.name}</Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Box style={{ margin: '10px' }} border={1} borderRadius={5} borderColor="#ccc" flex="1">
                                  <List
                                    component="ul"
                                    aria-labelledby="list-subheader"
                                    subheader={(
                                      <li>
                                        <ListSubheader component="div" id="list-subheader">
                                          {t('Versions')}
                                          :
                                        </ListSubheader>
                                      </li>
                                    )}
                                  >
                                    {application.versions.map((version, i) => (
                                      <ListItem key={i.toString()}>
                                        <ListItemText
                                          primary={`v1.5-SNAPSHOT`}
                                        />
                                        <ListItemSecondaryAction>
                                          <IconButton
                                            edge="end"
                                            aria-label="download-application"
                                            onClick={() => { downloadApp(application.name, version); }}
                                          >
                                            <CloudDownload />
                                          </IconButton>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          ))}
                        </Grid>
                      </Grid>
                    )
                )}
                {value === t('intersections') && (
                  isLoadingIntersections ? <Loader />
                    : (
                      <Grid container direction="column" style={{ paddingTop: '15px' }}>
                        <Grid container>
                          <Grid item xs>
                            <InputSearch
                              handleSearch={handleSearch}
                            />
                          </Grid>
                        </Grid>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('Intersection')}</TableCell>
                              <TableCell>{t('Project')}</TableCell>
                              <TableCell>{t('lastEdited')}</TableCell>
                              <TableCell>{t('Information')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredIntersections.map((forProject) => (
                              forProject.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell>{row.identifier}</TableCell>
                                  <TableCell>{row.projectName}</TableCell>
                                  <TableCell>{format(new Date(row.modifiedDate), 'yyyy/MM/dd (hh:mm a)')}</TableCell>
                                  <TableCell>N/A</TableCell>
                                </TableRow>
                              ))
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    )
                )}

                {(value === t('history') && isAdmin) && (
                  isLoadingHistory ? <Loader />
                    : (
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('executedAt')}</TableCell>
                            <TableCell>{t('executedBy')}</TableCell>
                            <TableCell>{t('eventName')}</TableCell>
                            <TableCell>{t('eventDescription')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ROWS_HISTORY.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{format(new Date(row.executed), 'yyyy/MM/dd (hh:mm a)')}</TableCell>
                              <TableCell>{row.username}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.data}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default Profile;
