import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputSearchStyles from './InputSearch.styles';

const useStyles = makeStyles((theme) => (InputSearchStyles(theme)));

const InputSearch = ({ handleSearch }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box border={1} className={classes.search}>
      <Box className={classes.searchIcon}>
        <SearchIcon />
      </Box>
      <InputBase
        placeholder={`${t('search')}...`}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'Search' }}
        onKeyUp={handleSearch}
      />
    </Box>
  );
};

InputSearch.propTypes = {
  handleSearch: propTypes.func.isRequired,
};

export default InputSearch;
