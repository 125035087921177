import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import uuid from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import {
  AppBar, Typography, Tabs, Tab, Paper, Grid, Container, Box,
  List, ListSubheader, ListItem, Checkbox, ListItemSecondaryAction, ListItemText,
  ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, TextField,
  Table, TableRow, TableCell, TableHead, TableBody, Fab,
  FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Header,
  SideNav,
  DialogWindow,
  InputSearch,
} from '../../common';

import UsersStyles from './Users.styles';
import {
  GROUP_ENTITIES_PATH,
  GROUP_MEMBER_ENTITIES_PATH,
  CERTIFICATES_PATH,
  MEMBERSHIPS_PATH,
  CUSTOMERS_PATH,
  dataNamespaceUrl,
} from '../../constants/url-related';
import {
  updateCustomerInfo,
  getIdByGroupNameAndUserName,
  updateCertificateInfo,
  addNewCertificate,
} from '../../api/user-service';

import {
  getDataByProvidedArrayOfUrls,
  getDataForPathName,
  postDataForPathName,
  getDataByUsernameAndPathname,
  deleteGroupMembersById,
  getGroupMemberEntityById,
} from '../../api/common-service';

const useStyles = makeStyles((theme) => (UsersStyles(theme)));

const FORM_DEFAULT_STATE = {
  country: '',
  state: '',
  location: '',
  organization: '',
  validityDays: '',
};

const Users = () => {
  const match = useRouteMatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const TAB_NAMES = [t('information'), t('groups'), t('certificates')];
  const { params: { userName } } = match;
  const { currentUser } = useSelector((state) => state.currentUserData);
  const [ROWS_GROUPS, setRowGroups] = useState([]);
  const [ALL_ROWS_GROUPS, setAllRowGroups] = useState([]);
  const [ROWS_CERTIFICATES, setRowCertificates] = useState([]);

  const [filteredGroups, setFilteredGroups] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openLicenseModal, setOpenLicenseModal] = useState(false);
  const [hasCertificates, setHasCertificates] = useState(false);
  const [formState, setFormState] = useState(FORM_DEFAULT_STATE);
  const [customer, setCustomer] = useState({
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    company: '',
  });
  const [tabValue, setTabValue] = useState(TAB_NAMES[0]);
  const toggleModalVisibility = (isOpen) => {
    setOpenModal(isOpen);
  };
  const toggleLicenseModalVisibility = (isOpen) => {
    setOpenLicenseModal(isOpen);
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;

    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSearch = ({ target: { value: v } }) => {
    if (v === '') {
      setFilteredGroups(ROWS_GROUPS);

      return;
    }
    const filteredArrGroups = ROWS_GROUPS.filter(
      (item) => (item.groupName.toLowerCase().includes(v.toLowerCase())),
    );

    setFilteredGroups(filteredArrGroups);
  };
  const handleAddGroup = () => {
    toggleModalVisibility(true);
  };
  const handleRenewCert = () => {
    toggleLicenseModalVisibility(true);
  };
  const setUserData = async (event) => {
    const { name, value } = event.target;

    setCustomer((prevState) => ({ ...prevState, [name]: value }));

    const userInformationRequest = {
      ...customer,
      [name]: value,
    };

    try {
      await updateCustomerInfo(customer.id, userInformationRequest);
      if (customer.certificateId) {
        await updateCertificateInfo(customer.certificateId, { organization: userInformationRequest.company });
      }
    } catch (err) {
      throw err.message;
    }
  };

  const getMembership = async () => {
    let groups;

    try {
      const { data } = await getDataByUsernameAndPathname(userName, MEMBERSHIPS_PATH);
      const { _embedded: { groupMemberEntities } } = data;
      const groupMemberEntitiesUrls = groupMemberEntities.map((member) => member._links.group.href);

      groups = await getDataByProvidedArrayOfUrls(groupMemberEntitiesUrls);
      groups = groups.map((group) => (group.data));
    } catch (err) {
      throw err.message;
    } finally {
      setRowGroups(groups);
      setFilteredGroups(groups);
    }
  };

  const handleCheckboxToggle = async (elementIndex) => {
    const group = ALL_ROWS_GROUPS[elementIndex];
    const groupMemberRequest = {
      user: `${dataNamespaceUrl}/userEntities/${userName}`,
      group: `${dataNamespaceUrl}/groupEntities/${group.id}`,
    };

    if (group.checked) {
      const { data: { id } } = await getIdByGroupNameAndUserName(group.name, userName);

      await deleteGroupMembersById(id);
      const allRowsArrayAfterDeletingMember = filteredGroups.filter((groupItem) => groupItem.id !== group.id);

      setFilteredGroups(allRowsArrayAfterDeletingMember);
    } else {
      const { data: { id: groupMemberId } } = await postDataForPathName(GROUP_MEMBER_ENTITIES_PATH, groupMemberRequest);
      const { data } = await getGroupMemberEntityById(groupMemberId);
      const newGroupMember = {
        createdBy: null,
        createdDate: data.createdDate,
        groupName: data.groupName,
        id: data.id,
        modifiedBy: null,
        modifiedDate: data.modifiedDate,
      };

      setFilteredGroups([...filteredGroups, newGroupMember]);
    }

    group.checked = !group.checked;
    setAllRowGroups([...ALL_ROWS_GROUPS]);
  };

  const getUserInfo = async () => {
    try {
      const customers = await getDataByUsernameAndPathname(userName, CUSTOMERS_PATH);

      setCustomer({
        ...customers.data._embedded.customerEntities[0],
        userName,
      });
    } catch (err) {
      throw err.message;
    }
  };

  const getGroupEntities = async () => {
    let groupsInfo = [];

    try {
      const { data:
        { _embedded: { groupEntities } } } = await getDataForPathName(GROUP_ENTITIES_PATH);

      groupsInfo = groupEntities.map(({ groupName, createdDate, id }) => ({
        id,
        name: groupName,
        description: 'N/A',
        createDate: format(new Date(createdDate), 'yyyy/MM/dd'),
        project: 'N/A',
        checked: false,
      }));
    } catch (err) {
      throw err.message;
    } finally {
      setAllRowGroups(groupsInfo);
    }
  };

  const getCertificateEntities = async () => {
    try {
      let { data:
        { _embedded: { certificateEntities } },
      } = await getDataByUsernameAndPathname(userName, CERTIFICATES_PATH);

      certificateEntities = certificateEntities.sort((a, b) => (new Date(b.createdDate) - new Date(a.createdDate)));

      setRowCertificates(certificateEntities.splice(1));
    } catch (err) {
      throw err.message;
    }
  };

  const modifyCertificate = async () => {
    const issued = `${format(new Date(), 'yyy-MM-dd')}T${format(new Date(), 'HH:mm:ss')}Z`;

    try {
      await updateCertificateInfo(customer.certificateId, {
        validityDays: formState.validityDays * 365,
        reservationId: uuid.v4(),
        issued,
      });
    } catch (err) {
      throw err.message;
    } finally {
      getCertificateEntities();
      toggleLicenseModalVisibility(false);
      setFormState(FORM_DEFAULT_STATE);
    }
  };

  const addCertificate = async () => {
    const issued = `${format(new Date(), 'yyy-MM-dd')}T${format(new Date(), 'HH:mm:ss')}Z`;

    if (formState.country.trim() === ''
      || formState.state.trim() === ''
      || formState.location.trim() === ''
      || formState.organization.trim() === '') {
      return;
    }

    try {
      const payloadBody = {
        country: formState.country,
        state: formState.state,
        location: formState.location,
        organization: formState.organization,
        organizationalUnit: '',
        validityDays: formState.validityDays * 365,
        issued,
        valid: true,
        user: `${dataNamespaceUrl}/userEntities/${userName}`,
      };

      await addNewCertificate(payloadBody);
    } catch (err) {
      throw err.message;
    } finally {
      getCertificateEntities();
      toggleLicenseModalVisibility(false);
      setFormState(FORM_DEFAULT_STATE);
    }
  };

  useEffect(() => {
    getMembership();
    getUserInfo();
    getGroupEntities();
    getCertificateEntities();
    // eslint-disable-next-line
  }, [userName]);

  useEffect(() => {
    if (ROWS_CERTIFICATES.length > 0) {
      setCustomer({
        ...customer,
        company: ROWS_CERTIFICATES[0].organization,
        certificateId: ROWS_CERTIFICATES[0].id,
      });
    }
    setHasCertificates(ROWS_CERTIFICATES.length > 0);
    // eslint-disable-next-line
  }, [ROWS_CERTIFICATES]);

  useEffect(() => {
    const filteredAllGroups = ALL_ROWS_GROUPS.map((item) => ({
      ...item,
      checked: ROWS_GROUPS.filter((assignedGroup) => (item.name === assignedGroup.groupName)).length > 0,
    }));

    setAllRowGroups(filteredAllGroups);
    // eslint-disable-next-line
  }, [ROWS_GROUPS]);

  return (
    <div className={classes.root}>
      <Header
        title={`${t('User')}: ${userName}`}
        subtitle={customer.email}
        name={currentUser}
      />
      <SideNav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <AppBar position="static" color="default" className={classes.tabs}>
                  <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {TAB_NAMES.map((tab) => (<Tab key={tab} value={tab} label={tab} />))}
                  </Tabs>
                </AppBar>
                {tabValue === t('information') && (
                  <Grid container>
                    <Grid item xs style={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
                      <Typography component="p" variant="body1">
                        {t('userInfo')}
                        :
                      </Typography>
                      <TextField
                        label={t('Username')}
                        value={customer.userName}
                        margin="normal"
                        onChange={setUserData}
                        name="userName"
                        disabled
                        style={{ marginRight: 10 }}
                      />
                      <TextField
                        label={t('firstName')}
                        value={customer.firstName}
                        margin="normal"
                        onChange={setUserData}
                        name="firstName"
                        style={{ marginRight: 10 }}
                      />
                      <TextField
                        label={t('lastName')}
                        value={customer.lastName}
                        margin="normal"
                        onChange={setUserData}
                        name="lastName"
                        style={{ marginRight: 10 }}
                      />
                      <TextField
                        label={t('Email')}
                        value={customer.email}
                        margin="normal"
                        onChange={setUserData}
                        name="email"
                      />
                      <TextField
                        label={t('Company')}
                        value={customer.company || ''}
                        margin="normal"
                        onChange={setUserData}
                        name="company"
                      />
                    </Grid>
                  </Grid>
                )}
                {tabValue === t('groups') && (
                  <Grid container direction="column" style={{ paddingTop: '10px' }}>
                    <Grid item xs>
                      <Grid container display="flex" alignItems="center">
                        <InputSearch
                          handleSearch={handleSearch}
                        />
                        <Box display="flex" justifyContent="flex-end" flex="1">
                          <Fab
                            color="primary"
                            aria-label="Add"
                            className={classes.fab}
                            onClick={handleAddGroup}
                            size="small"
                          >
                            <Edit />
                          </Fab>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('tableHeaders.name')}</TableCell>
                            <TableCell>{t('tableHeaders.description')}</TableCell>
                            <TableCell>{t('tableHeaders.created')}</TableCell>
                            <TableCell>{t('tableHeaders.projects')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredGroups.map((row) => (
                            <TableRow key={row.groupName}>
                              <TableCell>
                                <Link to={`/groups/${row.groupName}/${userName}`}>{row.groupName}</Link>
                              </TableCell>
                              <TableCell>N/A</TableCell>
                              <TableCell>{format(new Date(row.createdDate), 'yyyy/MM/dd (hh:mm a)')}</TableCell>
                              <TableCell>N/A</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                )}
                {tabValue === t('certificates') && (
                  <Grid container display="flex" style={{ paddingTop: '10px' }}>
                    <Grid item xs style={{ minWidth: '100%' }}>
                      <Box justifyContent="flex-end" display="flex" flex="1">
                        <Fab
                          color="primary"
                          aria-label="Add"
                          className={classes.fab}
                          onClick={handleRenewCert}
                          size="small"
                        >
                          {hasCertificates
                            ? (
                              <Edit />
                            )
                            : (
                              <Add />
                            )}
                        </Fab>
                      </Box>
                    </Grid>
                    <Grid item xs style={{ padding: 10, minWidth: '100%' }}>
                      {ROWS_CERTIFICATES.map((row) => (
                        <ExpansionPanel expanded={expanded === row.id} onChange={handleChange(row.id)} key={row.id}>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography className={classes.heading} align="center">
                              {format(new Date(row.issued), 'yyyy/MM/dd')}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <List
                              component="ul"
                              aria-labelledby="list-subheader"
                              subheader={(
                                <li>
                                  <ListSubheader component="div" id="list-subheader">
                                    {t('Info')}
                                    :
                                  </ListSubheader>
                                </li>
                              )}
                            >
                              <ListItem>
                                <Typography component="p" variant="subtitle1">
                                  {t('Validity')}
                                  :
                                  {` ${row.validityDays} `}
                                  days
                                </Typography>
                              </ListItem>
                              <ListItem>
                                <Typography component="p" variant="subtitle1">
                                  {t('Country')}
                                  :
                                  {` ${row.country}`}
                                </Typography>
                              </ListItem>
                              <ListItem>
                                <Typography component="p" variant="subtitle1">
                                  {t('City')}
                                  :
                                  {` ${row.state}`}
                                </Typography>
                              </ListItem>
                              <ListItem>
                                <Typography component="p" variant="subtitle1">
                                  {t('customerDownloadLink')}
                                  :
                                  {row.reservationId
                                    ? ` https://${window.location.hostname}/#/license-download/${row.reservationId}`
                                    : ` ${t('pleaseRenew')}`}
                                </Typography>
                              </ListItem>
                            </List>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
      {tabValue === t('groups') && (
        <DialogWindow
          title={t('dialogWindows.assignUserToGroups')}
          openModal={openModal}
          toggleModalVisibility={toggleModalVisibility}
          okAction={() => { toggleModalVisibility(false); }}
          hasCancelButton={false}
        >
          <List>
            {ALL_ROWS_GROUPS.map((row, index) => (
              <ListItem key={row.id} role={undefined} dense button onClick={() => { handleCheckboxToggle(index); }}>
                <ListItemText id={row.id} primary={row.name} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="start"
                    checked={row.checked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${row.id}` }}
                    color="primary"
                    onClick={() => { handleCheckboxToggle(index); }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogWindow>
      )}
      <DialogWindow
        title={hasCertificates ? `${t('dialogWindows.renewLicenseFor')}:` : `Add new license for user ${customer.userName}`}
        openModal={openLicenseModal}
        toggleModalVisibility={toggleLicenseModalVisibility}
        okAction={hasCertificates ? modifyCertificate : addCertificate}
      >
        {hasCertificates ? (
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="simple-select-outlined-label">{t('dialogWindows.years')}</InputLabel>
            <Select
              value={formState.validityDays}
              onChange={handleOnChange}
              labelWidth={40}
              id="validityDays"
              name="validityDays"
            >
              {[1, 5, 10].map((year) => (
                <MenuItem value={year} key={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )
          : (
            <>
              <TextField
                key="country"
                margin="dense"
                id="country"
                name="country"
                label={t('dialogWindows.country')}
                type="text"
                fullWidth
                onChange={handleOnChange}
              />
              <TextField
                key="state"
                margin="dense"
                id="state"
                name="state"
                label={t('dialogWindows.state')}
                type="text"
                fullWidth
                onChange={handleOnChange}
              />
              <TextField
                key="location"
                margin="dense"
                id="location"
                name="location"
                label={t('dialogWindows.location')}
                type="text"
                fullWidth
                onChange={handleOnChange}
              />
              <TextField
                key="organization"
                margin="dense"
                id="organization"
                name="organization"
                label={t('dialogWindows.organization')}
                type="text"
                fullWidth
                onChange={handleOnChange}
              />
              <FormControl variant="outlined" fullWidth style={{ marginTop: 15 }}>
                <InputLabel id="simple-select-outlined-label">{t('dialogWindows.years')}</InputLabel>
                <Select
                  value={formState.validityDays}
                  onChange={handleOnChange}
                  labelWidth={40}
                  id="validityDays"
                  name="validityDays"
                >
                  {[1, 5, 10].map((year) => (
                    <MenuItem value={year} key={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
      </DialogWindow>
    </div>
  );
};

export default Users;
