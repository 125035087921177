import React from 'react';
import {
  FormControl, InputLabel, Select, MenuItem, Table, TableBody,
  TableHead, TableRow, TableCell, Typography, Box, TextField, Button, Tooltip,
} from '@material-ui/core';
import CloseRounded from '@material-ui/icons/CloseRounded';
import { format } from 'date-fns';
import {
  DialogWindow,
} from '../../common';

const renderDialogsContent = (
  tabsValue,
  t,
  classes,
  openModal,
  toggleModalVisibility,
  handleOnChange,
  addNewUser,
  addNewGroup,
  modifiedUserOrGroupRole,
  addNewRole,
  formState,
  intersectionsDetailsForProject,
  addNewIntersection,
  removeIntersection,
  renderIntersectionSignature,
  isWholeSignatureVisible,
  setIsWholeSignatureVisible,
  projectIntersections,
  addNewEula,
) => {
  switch (tabsValue) {
    case t('users'):
      return (
        <DialogWindow
          title={t('dialogWindows.createNewUser')}
          inputsData={[
            { id: 'username', label: t('dialogWindows.username') },
            { id: 'firstname', label: t('dialogWindows.firstName') },
            { id: 'lastname', label: t('dialogWindows.lastName') },
            { id: 'email', label: t('dialogWindows.email') },
            { id: 'company', label: t('dialogWindows.companyName') },
            { id: 'organizationalUnit', label: t('dialogWindows.organizationalUnit') },
            { id: 'country', label: t('dialogWindows.country') },
            { id: 'location', label: t('dialogWindows.location') },
            { id: 'state', label: t('dialogWindows.state') },
          ]}
          openModal={openModal}
          toggleModalVisibility={toggleModalVisibility}
          handleOnChange={handleOnChange}
          okAction={addNewUser}
        />
      );
    case t('groups'):
      return (
        <DialogWindow
          title={t('dialogWindows.createNewGroup')}
          inputsData={[
            { id: 'groupname', label: t('dialogWindows.groupName') },
          ]}
          openModal={openModal}
          toggleModalVisibility={toggleModalVisibility}
          handleOnChange={handleOnChange}
          okAction={addNewGroup}
        />
      );
    case t('roles'):
      return (
        <DialogWindow
          title={t('dialogWindows.addNewRoleTo', {
            roleName: modifiedUserOrGroupRole.value,
          })}
          openModal={openModal}
          toggleModalVisibility={toggleModalVisibility}
          handleOnChange={handleOnChange}
          okAction={addNewRole}
        >
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="simple-select-outlined-label">{t('dialogWindows.Role')}</InputLabel>
            <Select
              value={formState.rolename}
              onChange={handleOnChange}
              labelWidth={40}
              id="rolename"
              name="rolename"
            >
              <MenuItem value="ROLE_ADMIN">ROLE_ADMIN</MenuItem>
              <MenuItem value="ROLE_SUPPORT">ROLE_SUPPORT</MenuItem>
              <MenuItem value="ROLE_CUSTOMER">ROLE_CUSTOMER</MenuItem>
            </Select>
          </FormControl>
        </DialogWindow>
      );
    case t('projects'):
      return (
        <DialogWindow
          title={t('dialogWindows.intersectionsForProject', { projectName: intersectionsDetailsForProject })}
          openModal={openModal}
          toggleModalVisibility={toggleModalVisibility}
          okAction={() => { toggleModalVisibility(false); }}
          hasCancelButton={false}
        >
          <>
            <Box display="flex">
              <TextField
                key="identifier"
                margin="dense"
                id="identifier"
                name="identifier"
                label={t('dialogWindows.intersectionIdentifier')}
                type="text"
                onChange={handleOnChange}
                style={{ width: '64%' }}
                value={formState.identifier}
              />
              <Button
                color="primary"
                aria-label="Add intersection"
                onClick={() => {
                  addNewIntersection(intersectionsDetailsForProject);
                }}
                size="medium"
                style={{ margin: 15, marginLeft: 'auto' }}
                variant="outlined"
              >
                {t('addIntersection')}
              </Button>
            </Box>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('dialogWindows.tableHeaders.Identifier')}</TableCell>
                  <TableCell>
                    {t('dialogWindows.tableHeaders.Signature')}
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => { setIsWholeSignatureVisible(!isWholeSignatureVisible); }}
                      style={{ fontSize: 12 }}
                    >
                      {isWholeSignatureVisible ? t('showLess') : t('showMore')}
                    </Button>
                  </TableCell>
                  <TableCell>{t('dialogWindows.tableHeaders.createdDate')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {projectIntersections.map((intersection, i) => (
                  <TableRow key={i.toString()}>
                    <TableCell>{intersection.identifier}</TableCell>
                    <TableCell>
                      <Typography style={{ fontSize: 10 }} paragraph>
                        {isWholeSignatureVisible ? intersection.signature : renderIntersectionSignature(intersection.signature)}
                      </Typography>
                    </TableCell>
                    <TableCell>{format(new Date(intersection.createdDate), 'yyyy/MM/dd')}</TableCell>
                    <TableCell className={classes.removeOnHover}>
                      <span>
                        <Tooltip title={t('DeleteIntersection')} placement="top">
                          <CloseRounded
                            fontSize="small"
                            color="primary"
                            onClick={() => {
                              removeIntersection(intersection);
                            }}
                          />
                        </Tooltip>
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        </DialogWindow>
      );
    case t('eula'):
      return (
        <DialogWindow
          title={t('dialogWindows.createEula')}
          inputsData={[
            { id: 'eulaVersion', label: t('dialogWindows.eulaVersion') },
            { id: 'eulaContent', label: t('dialogWindows.Content'), multiline: true, rows: 6 },
          ]}
          openModal={openModal}
          toggleModalVisibility={toggleModalVisibility}
          handleOnChange={handleOnChange}
          okAction={addNewEula}
        />
      );
    default:
      return null;
  }
};

export default renderDialogsContent;
