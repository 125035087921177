import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar, Typography, Tabs, Tab, Paper, Grid, Container,
  List, ListItem, Link, Collapse,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { addDays, differenceInSeconds } from 'date-fns';
import { HeaderLogo, CountDownTimer } from '../../common';
import { helpDeskPortalUrl } from '../../constants/url-related';
import LicenseDownloadStyles from './LicenseDownload.styles';

import { getLicenseInfo } from '../../api/license-service';

const useStyles = makeStyles((theme) => (LicenseDownloadStyles(theme)));

const LicenseDownload = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { t } = useTranslation();
  const [value, setValue] = useState(t('information'));
  const [validityRangeInSeconds, setValidityRangeInSeconds] = useState(60);
  const [messageVisibility, setHelpMessageVisibility] = useState(false);

  const onExpire = () => {
    setHelpMessageVisibility(true);
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getLicenseInformation = async () => {
      let difference;

      try {
        const { data: { createdDate, retention: validForDays } } = await getLicenseInfo(id);
        const validUntilDate = addDays(new Date(createdDate), validForDays);

        difference = differenceInSeconds(validUntilDate, new Date());
      } catch (err) {
        throw err.message;
      } finally {
        if (difference <= 0) {
          setHelpMessageVisibility(true);
        }
        setValidityRangeInSeconds(difference);
      }
    };

    getLicenseInformation();
  }, [id]);

  return (
    <div className={classes.root}>
      <HeaderLogo />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <AppBar position="static" color="default" className={classes.tabs}>
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab key={t('information')} value={t('information')} label={t('information')} />
                  </Tabs>
                </AppBar>
                <Grid container justify="flex-end">
                  <Link href={helpDeskPortalUrl} className={classes.helpDeskLink} target="_blank">
                    {t('AxilionServiceDesk')}
                  </Link>
                </Grid>
                {value === t('information') && (
                  <Grid container direction="column">
                    <Grid item xs>
                      <List
                        component="ul"
                      >
                        <ListItem>
                          <Typography component="p" variant="subtitle1">
                            {t('downloadLinkFrom')}
                            {': '}
                            <Link href="/license-download">
                              {`https://${window.location.hostname}/license/generate/com.axilion.service:license:jar:1.5-SNAPSHOT`}
                            </Link>
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography component="p" variant="subtitle1">
                            {t('expireLicense')}
                            {': '}
                            <CountDownTimer
                              validityRangeInSeconds={validityRangeInSeconds}
                              onExpire={onExpire}
                            />
                          </Typography>
                        </ListItem>
                        <Collapse in={messageVisibility}>
                          <ListItem>
                            <Typography component="p" variant="subtitle1">
                              Your license download link has expired. Please contact
                              <Link href={helpDeskPortalUrl} target="_blank">
                                {' '}
                                {t('axilionSupportTeam')}
                                {' '}
                              </Link>
                              for help!
                            </Typography>
                          </ListItem>
                        </Collapse>
                      </List>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default LicenseDownload;
