export default (theme) => ({
  appBar: {
    marginBottom: 32,
  },
  logo: {
    width: '180px',
    height: '100%',
    padding: 6,
    margin: '0 auto',
    backgroundColor: theme.palette.secondary.main,
  },
});
