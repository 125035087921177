export default (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  heading: {
    textAlign: 'center',
    width: '100%',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(0),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  contentBox: {
    paddingTop: 15,
  },
  fab: {
    margin: theme.spacing(2),
  },
  helpDeskLink: {
    margin: theme.spacing(2),
    fontSize: 12,
  },
});
