import { format } from 'date-fns';
import { filterPermissions } from './filteringHelpers';
/**
 * @description compose(build) user object
 * @param  {Object} customer customer object with required properties id, firstName, lastName, createDate, modifiedData
 * and optional property email if email is undefined, ‘N/A’ is assigned as default value
 * @param  {Object} user user object with required properties username and enabled
 * @param  {Object} eula eula object with required property version
 * @param {Object} certificate the certificate for the current user, if certificate.organization is undefined, N/A is assigned it as default value for companyName property in the composeUserObject
 * @returns {Object}
 */
const composeUserObject = (customer, user, eula, certificate) => ({
  id: customer.id,
  username: user.username,
  name: `${customer.firstName} ${customer.lastName}`,
  email: customer.email !== 'NULL' ? customer.email : 'N/A',
  registered: format(new Date(customer.createdDate), 'yyyy/MM/dd'),
  validUntil: format(new Date(customer.modifiedDate), 'yyyy/MM/dd (hh:mm a)'),
  eula: eula.version ? eula.version : 'N/A',
  isActive: user.enabled,
  firstName: customer.firstName,
  lastName: customer.lastName,
  companyName: certificate.organization !== 'NULL' ? certificate.organization : 'N/A',
});

/**
 * @description compose(build) resource object
 * @param  {Object} resourceObject resourceObject object with required properties id, kind, version, name
 * @param  {Set} permissions permissions is a Set structure with values { readResource: Bool, writeResource: Bool, createResource: Bool, deleteResource: Bool }
 * @returns {Object}
 */
const composeResourceObjectWithPermissions = (resourceObject, permissions) => {
  const currentPermissions = filterPermissions(permissions);
  const newProjectToBeAdded = {
    id: resourceObject.id,
    kind: resourceObject.kind,
    name: resourceObject.name,
    data: [{
      permissions: [...currentPermissions],
      version: resourceObject.version,
    },
    ],
  };

  return newProjectToBeAdded;
};

/**
 * @description compose(build) applicationsOrProjects array of objects every object has structure { kind: 'PROJECT'|'APPLICATION', data: [ version: 'nameOfVersion',
 * permissions: ['create' (&& / |)* 'read' (&& / |)* 'write' (&& / |)* 'delete'] ] }. (&& / |)* - means and this value or this value
 * @param  {array} projectsOrApplications this is the array of objects used in the groups route for tabs 'PROJECTS' or 'APPLICATIONS' that renders the  tables
 * @param  {string} nameOfProjectOrApplication the name of the project that we would like to add the new version with permissions toward this version
 * @param {string} newVersionOfProjectOrApplication the name of the new version that should be added to the projects or applications
 * @param {array} array of permissions that will be added to the new version
 * @returns {array} arrayOfProjectsOrApplications return value is array of projects or applications depend of the kind(with values 'PROJECTS' or 'APPLICATIONS') property in every
 * object in the array applicationsOrProjects
 */
const composeProjectsOrApplicationsWithVersionAndPermissions = (
  projectsOrApplications,
  nameOfProjectOrApplication,
  newVersionOfProjectOrApplication,
  permissions,
) => {
  const allProjectsOrApplicationsWithAllVersionsAndPermissions = projectsOrApplications.map((projectOrApplication) => {
    if (projectOrApplication.name === nameOfProjectOrApplication) {
      const arrayOfPermissions = filterPermissions(permissions);

      const currentProjectWithNewVersionAndPermissions = {
        ...projectOrApplication,
        data: [...projectOrApplication.data, { version: newVersionOfProjectOrApplication, permissions: arrayOfPermissions }],
      };

      return currentProjectWithNewVersionAndPermissions;
    }

    return projectOrApplication;
  });


  return allProjectsOrApplicationsWithAllVersionsAndPermissions;
};

export {
  composeUserObject,
  composeResourceObjectWithPermissions,
  composeProjectsOrApplicationsWithVersionAndPermissions,
};
