export default {
  translation: {
    information: 'information',
    Information: 'Information',
    applications: 'applications',
    members: 'members',
    intersections: 'intersections',
    Intersections: 'Intersections',
    Intersection: 'Intersection',
    history: 'history',
    users: 'users',
    groups: 'groups',
    certificates: 'certificates',
    roles: 'roles',
    projects: 'projects',
    Projects: 'Projects',
    Project: 'Project',
    eula: 'eula',
    search: 'Search',
    tableHeaders: {
      username: 'Username',
      name: 'Name',
      email: 'E-mail',
      company: 'Company',
      registered: 'Registered',
      modifiedAt: 'Modified at',
      eula: 'Eula',
      active: 'Active',
      description: 'Description',
      created: 'Created',
      projects: 'Projects',
      project: 'Project',
      version: 'Version',
      versions: 'Versions',
      usedIntersections: 'Used Intersections',
      totalIntersections: 'Total Intersections',
      usersAccepted: 'Users Accepted',
    },
    Groups: 'Groups',
    noGroups: 'No group roles available',
    Users: 'Users',
    User: 'User',
    noUsers: 'No user roles available',
    Details: 'Details',
    addIntersection: 'Add intersection',
    noProjects: 'No projects available',
    dialogWindows: {
      intersectionIdentifier: 'Intersection identifier',
      deleteConfirmMessage: 'Do you really want to delete the {{resourceName}} {{resourceType}}?',
      warningConfirmMessage: 'Warning: All the data related with {{resourceName}} {{resourceType}} will be deleted permanently.',
      createNewUser: 'Create new user',
      createNewGroup: 'Create new group',
      name: 'Name',
      version: 'Version',
      username: 'Username',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'E-mail',
      companyName: 'Company Name',
      organizationalUnit: 'Organizational Unit',
      country: 'Country',
      location: 'Location',
      state: 'State',
      organization: 'Organization',
      groupName: 'Group Name',
      addNewRoleTo: 'Add new role to: {{roleName}}',
      Role: 'Role',
      intersectionsForProject: 'Intersections IDs for project: {{projectName}}',
      createEula: 'Create new EULA',
      eulaVersion: 'EULA version',
      Content: 'Content',
      tableHeaders: {
        Identifier: 'Identifier',
        Signature: 'Signature',
        createdDate: 'Created date',
      },
      assignUserToGroups: 'Assign user to groups',
      createNewResourceType: 'Create new {{resourceType}}',
      createNewVersion: 'Create new version for {{resourceType}}: {{resourceName}}',
      cloneNewVersion: 'Clone new version for {{resourceType}}: {{resourceName}} (all features will be copied)',
      pickAtLeastOne: 'Pick at least one permission',
      createNewFeature: 'Create new feature for {{resourceType}} {{resourceNameForEdit}} v{{versionToEdit}}',
      featureName: 'Feature name',
      renewLicenseFor: 'Renew license for',
      years: 'Years',
    },
    profileInfo: 'Profile info:',
    Name: 'Name',
    Username: 'Username',
    Email: 'Email',
    Company: 'Company',
    Signed: 'Signed',
    Versions: 'Versions',
    AddVersion: 'Add version',
    AddFeature: 'Add feature',
    CloneVersion: 'Clone version',
    DeleteVersion: 'Delete version',
    DeleteFeature: 'Delete feature',
    DeleteProject: 'Delete project',
    DeleteApplication: 'Delete application',
    DeleteIntersection: 'Delete intersection',
    Permissions: 'Permissions',
    Features: 'Features',
    Applications: 'Applications',
    lastEdited: 'Last edited',
    executedAt: 'Executed at',
    executedBy: 'Executed by',
    eventName: 'Event name',
    eventDescription: 'Event description',
    firstName: 'First name',
    lastName: 'Last name',
    userInfo: 'User info',
    Info: 'Info',
    Validity: 'Validity',
    Country: 'Country',
    City: 'City',
    groupName: 'Group name',
    moreInfo: 'More info',
    assignUsersToGroup: 'Assign users to group',
    Read: 'Read',
    Write: 'Write',
    Create: 'Create',
    Delete: 'Delete',
    AxilionServiceDesk: 'Axilion Help Center - Service Desk',
    axilionSupportTeam: 'Axilion support team',
    addNewFeatureLabel: 'Add feature for all versions',
    expireLicense: 'Your license download link will expire after',
    downloadLinkFrom: 'You can download license from here',
    customerDownloadLink: 'Customer download link',
    pleaseRenew: 'Please renew the license to get download url',
    noRolesAssigned: 'No roles assigned',
    showMore: 'Show more',
    showLess: 'Show less',
  },
};
