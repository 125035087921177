/* eslint-disable max-len */

import { get } from './api';

import { dataNamespaceUrl } from '../constants/url-related';
/**
 * @description the total request will hit end-point ('/data/intersectionEntities/search/countByAllocationProjectName', {}, {}, options)
 * @param  {Object} options
 * @returns {Promise}
 */
export const getAllocationsCountByProjectName = (options) => get(`${dataNamespaceUrl}/intersectionEntities/search/countByAllocationProjectName`, {}, {}, options);

/**
 * @description the total request will hit end-point ('/data/userEntities/${username}/customers', {}, headers)
 * @param  {Object} username
 * @param  {Object} headers
 * @returns {Promise}
 */
export const getUserCustomers = (username, headers) => get(`${dataNamespaceUrl}/userEntities/${username}/customers`, {}, headers);

/**
 * @description total request will hit end-point ('/data/aclEntryEntities/search/findBySidSid?sid=${username}')
 * @param {String} username
 * @returns {Promise}
 */
export const getAclEntryEntitiesBySid = (username) => get(`${dataNamespaceUrl}/aclEntryEntities/search/findBySidSid?sid=${username}`);
