import React from 'react';
import propTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  TextField,
} from '@material-ui/core';
/**
 * @file DialogWindow component.
 *
 * @description DialogWindow used across the whole application
 * @param {string} title The title of the DialogWindow component
 * @param {array} inputsData array of components to be rendered inside of the DialogWindow component
 * @param {boolean} openModal based on this prop the window is shown or not
 * @param {function} toggleModalVisibility callback(cb) passed from parent component, based on this cb visibility is switched
 * @param {function} okAction callback(cb) passed from parent component, based on this cb actions are taken when
 * the user click ok(agree) in the window
 * @param {array} children, array of components that are inside of <DialogWindow> component the example: <DialogWindow><Button>OK</Button></DialogWindow>
 * in this case <Button>OK</Button> is the children of the <DialogWindow> component
 * @param {function} handleOnChange callback(cb) passed by the parent that is triggered when change occurred
 * @param {boolean} hasCancelButton boolean value if is true Cancel button is rendered in the DialogWindow
 */
const DialogWindow = ({
  title,
  inputsData,
  openModal,
  toggleModalVisibility,
  handleOnChange,
  okAction,
  children,
  hasCancelButton,
}) => {
  let contentToRender = children;

  if (!children) {
    contentToRender = inputsData.map((data) => (
      <TextField
        key={data.id}
        margin="dense"
        id={data.id}
        name={data.id}
        label={data.label}
        type="text"
        fullWidth
        onBlur={handleOnChange}
        multiline={data.multiline}
        rows={data.rows}
      />
    ));
  }

  return (
    <Dialog
      open={openModal}
      onClose={() => { toggleModalVisibility(false); }}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {contentToRender}
      </DialogContent>
      <DialogActions>
        {hasCancelButton && (
          <Button onClick={() => { toggleModalVisibility(false); }} color="primary">
            Cancel
          </Button>
        )}
        <Button onClick={okAction} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogWindow.propTypes = {
  title: propTypes.string.isRequired,
  inputsData: propTypes.array,
  openModal: propTypes.bool.isRequired,
  toggleModalVisibility: propTypes.func.isRequired,
  handleOnChange: propTypes.func,
  okAction: propTypes.func.isRequired,
  hasCancelButton: propTypes.bool,
  children: propTypes.element,
};

DialogWindow.defaultProps = {
  inputsData: [],
  handleOnChange: () => { },
  hasCancelButton: true,
  children: propTypes.children,
};

export default DialogWindow;
