export default (theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    display: 'inline-block',
    width: 250,
  },
  searchIcon: {
    width: theme.spacing(5),
    color: theme.palette.secondary.main,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
  },
});
