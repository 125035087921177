//  Base urls
export const baseUrl = (() => {
  if (window.location.hostname === 'localhost' && window.location.port === '3000') {
    return 'http://localhost:8080';
  }

  return '';
})();
export const dataNamespaceUrl = `${baseUrl}/data`;
export const helpDeskPortalUrl = 'https://axilion.atlassian.net/servicedesk/customer/portals';

//  URL paths for Admin.js screen
export const AUTHORITY_ENTITIES_PATH = 'authorityEntities';
export const ALLOCATION_ENTITIES_PATH = 'allocationEntities';
export const CUSTOMER_ENTITIES_PATH = 'customerEntities';
export const AUTHORITIES_ENTITIES_PATH = 'authorityEntities';
export const CERTIFICATE_ENTITIES_PATH = 'certificateEntities';
export const EULA_ENTITIES_PATH = 'eulaEntities';
export const GROUP_ENTITIES_PATH = 'groupEntities';
export const GROUP_AUTHORITY_ENTITIES_PATH = 'groupAuthorityEntities';
export const USER_ENTITIES_PATH = 'userEntities';
export const RESOURCE_ENTITIES_PATH = 'resourceEntities';
export const INTERSECTION_ENTITIES = 'intersectionEntities';

//  URL paths for User.js screen
export const GROUP_MEMBER_ENTITIES_PATH = 'groupMemberEntities';
export const AUTHORITIES_PATH = 'authorities';
export const CERTIFICATES_PATH = 'certificates';
export const MEMBERSHIPS_PATH = 'memberships';
export const CUSTOMERS_PATH = 'customers';

//  URL paths for Group.js screen
export const ACL_OBJECT_IDENTITY_ENTITIES_PATH = 'aclObjectIdentityEntities';
export const ACL_SID_ENTITIES_PATH = 'aclSidEntities';
export const ACL_ENTRY_ENTITIES_PATH = 'aclEntryEntities';
export const FEATURE_ENTITIES_PATH = 'featureEntities';
//  URL paths for Profile.js screen
export const EVENTS_ENTITIES_PATH = 'eventsEntities';
