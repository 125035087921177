import React from 'react';
import { Grid, Box, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  Loader,
  InputSearch,
  UsersTable,
  ListPanel,
  GroupsTable,
  EulasTable,
  ProjectsList,
} from '../../common';

const renderTabsContent = (
  tabsValue,
  isLoadingUsers,
  t,
  handleSearch,
  classes,
  toggleModalVisibility,
  filteredUsers,
  currentUser,
  handleIsActive,
  isLoadingGroups,
  isLoadingProjectsForGroup,
  filteredGroups,
  ROWS_GROUPS,
  expanded,
  handleChange,
  groupRoles,
  deleteGroupAuthority,
  setModifiedUserOrGroupRole,
  ROWS_USERS,
  userRoles,
  deleteUserAuthority,
  isLoadingProjects,
  ROWS_PROJECTS,
  usedIntersections,
  totalIntersections,
  setIntersectionDetailsForProject,
  isLoadingEulas,
  ROWS_EULA,
) => {
  switch (tabsValue) {
    case t('users'):
      return (
        isLoadingUsers ? <Loader />
          : (
            <Grid container direction="column" style={{ paddingTop: '15px' }}>
              <Grid item xs>
                <Grid container display="flex" alignItems="center">
                  <InputSearch
                    handleSearch={handleSearch}
                  />
                  <Box display="flex" justifyContent="flex-end" flex="1">
                    <Fab
                      color="primary"
                      aria-label="Add"
                      className={classes.fab}
                      onClick={() => { toggleModalVisibility(true); }}
                      size="small"
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs>
                <UsersTable
                  headers={[
                    t('tableHeaders.username'),
                    t('tableHeaders.name'),
                    t('tableHeaders.email'),
                    t('tableHeaders.company'),
                    t('tableHeaders.registered'),
                    t('tableHeaders.modifiedAt'),
                    t('tableHeaders.eula'),
                    t('tableHeaders.active'),
                  ]}
                  users={filteredUsers}
                  currentUser={currentUser}
                  handleIsActive={handleIsActive}
                />
              </Grid>
            </Grid>
          )
      );
    case t('groups'):
      return (
        isLoadingGroups ? <Loader />
          : (
            <Grid container direction="column" style={{ paddingTop: '15px' }}>
              <Grid item xs>
                <Grid container display="flex" alignItems="center">
                  <InputSearch
                    handleSearch={handleSearch}
                  />
                  <Box display="flex" justifyContent="flex-end" flex="1">
                    <Fab
                      color="primary"
                      aria-label="Add"
                      className={classes.fab}
                      onClick={() => { toggleModalVisibility(true); }}
                      size="small"
                      disabled={isLoadingProjectsForGroup}
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs>
                <GroupsTable
                  headers={[
                    t('tableHeaders.name'),
                    t('tableHeaders.description'),
                    t('tableHeaders.created'),
                    t('tableHeaders.projects'),
                  ]}
                  groups={filteredGroups}
                  isLoadingProjectsForGroup={isLoadingProjectsForGroup}
                />
              </Grid>
            </Grid>
          )
      );
    case t('roles'):
      return (
        isLoadingUsers ? <Loader />
          : (
            <Grid container>
              <Grid item xs style={{ padding: '10px' }}>
                <ListPanel
                  items={ROWS_GROUPS}
                  expanded={expanded}
                  handleChange={handleChange}
                  subList={groupRoles}
                  deleteAction={deleteGroupAuthority}
                  setAction={setModifiedUserOrGroupRole}
                  toggleModalVisibility={toggleModalVisibility}
                  isGroup
                />
              </Grid>
              <Grid item xs style={{ padding: '10px' }}>
                <ListPanel
                  items={ROWS_USERS}
                  expanded={expanded}
                  handleChange={handleChange}
                  subList={userRoles}
                  deleteAction={deleteUserAuthority}
                  setAction={setModifiedUserOrGroupRole}
                  toggleModalVisibility={toggleModalVisibility}
                  isGroup={false}
                />
              </Grid>
            </Grid>
          )
      );
    case t('projects'):
      return (
        isLoadingProjects ? <Loader />
          : (
            <Grid container>
              <Grid item xs style={{ padding: '10px' }}>
                <ProjectsList
                  projects={ROWS_PROJECTS}
                  hasProjects={ROWS_PROJECTS.length > 0}
                  expanded={expanded}
                  handleChange={handleChange}
                  intersections={{
                    usedIntersections,
                    totalIntersections,
                  }}
                  setIntersectionDetailsForProject={setIntersectionDetailsForProject}
                  toggleModalVisibility={toggleModalVisibility}
                />
              </Grid>
            </Grid>
          )
      );
    case t('eula'):
      return (
        isLoadingEulas ? <Loader />
          : (
            <Grid container direction="column">
              <Grid item xs>
                <Grid container display="flex" alignItems="center">
                  <Box display="flex" justifyContent="flex-end" flex="1">
                    <Fab
                      color="primary"
                      aria-label="Add"
                      className={classes.fab}
                      onClick={() => { toggleModalVisibility(true); }}
                      size="small"
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs>
                <EulasTable
                  headers={[
                    t('tableHeaders.version'),
                    t('tableHeaders.created'),
                    t('tableHeaders.modifiedAt'),
                    t('tableHeaders.usersAccepted'),
                  ]}
                  eulas={ROWS_EULA}
                />
              </Grid>
            </Grid>
          )
      );
    default:
      return null;
  }
};

export default renderTabsContent;
