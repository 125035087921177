export default () => ({
  heading: {
    textAlign: 'center',
    width: '100%',
  },
  iconButton: {
    marginLeft: 'auto',
    marginRight: 10,
  },
});
