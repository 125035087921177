import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import Loader from '../Loader/Loader';

const GroupsTable = ({ headers, groups, isLoadingProjectsForGroup }) => (
  <Table>
    <TableHead>
      <TableRow>
        {headers.map((header) => (<TableCell key={header}>{header}</TableCell>))}
      </TableRow>
    </TableHead>
    <TableBody>
      {groups.map((row) => (
        <TableRow key={row.name}>
          <TableCell>
            <Link to={`/groups/${row.name}`}>{row.name}</Link>
          </TableCell>
          <TableCell>{row.description}</TableCell>
          <TableCell>{row.createDate}</TableCell>
          <TableCell>
            {isLoadingProjectsForGroup ? <Loader containerPadding={0} size={15} alignItems="flex-start" />
              : (row.projects.join(', '))}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

GroupsTable.propTypes = {
  headers: propTypes.array.isRequired,
  groups: propTypes.array.isRequired,
  isLoadingProjectsForGroup: propTypes.bool.isRequired,
};

export default GroupsTable;
