/* eslint-disable max-len */

import { get } from './api';
import { baseUrl } from '../constants/url-related';

/**
 * @description fetch license info information
 * @param  {String} id
 * @returns {Promise}
 */
export const getLicenseInfo = (id) => get(`${baseUrl}/license-download/com.axilion.service:license:jar:1.5-SNAPSHOT`);
