import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './lang/en';

const availableLanguages = ['en', 'he'];

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: { en },
    fallbackLng: 'en',
    whitelist: availableLanguages,
    react: {
      wait: true,
    },
    interpolation: {
      escapeValue: false,
      format: (value, format /* lng */) => {
        switch (format) {
          case 'uppercase': return value.toUppercase();
          case 'capitalize': return value.charAt(0).toUpperCase() + value.slice(1);
          default: return value;
        }
      },
    },
    parseMissingKeyHandler: (key) => `No translation found for "${key}"`,
  });
