export const ADMIN_FORM_DEFAULT_STATE = {
  username: '',
  firstname: '',
  lastname: '',
  email: '',
  groupname: '',
  eulaVersion: '',
  eulaContent: '',
  rolename: '',
  company: '',
  organizationalUnit: '',
  country: '',
  location: '',
  state: '',
  identifier: '',
};
