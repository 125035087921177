import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';

let composeEnhancers = compose;

const logger = createLogger({
  predicate: () => process.env.NODE_ENV === 'development',
});

if (process.env.NODE_ENV === 'development') {
  // https://github.com/zalmoxisus/redux-devtools-extension
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle
}

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(
    applyMiddleware(thunkMiddleware, logger),
  ),
);

export default store;
