/* eslint-disable max-len */
import { get, del } from './api';

import { dataNamespaceUrl } from '../constants/url-related';

/**
 * @description total request will hit end-point with GET ('/data/intersectionEntities/search/countByAllocationProjectName', {}, {}, options))
 * @returns {Promise}
 */
export const getCountByAllocationProjectName = (options) => get(`${dataNamespaceUrl}/intersectionEntities/search/countByAllocationProjectName`, {}, {}, options);

/**
 * @description total request will hit end-point with DELETE '/data/${urlPathName}/com.axilion.service:license:jar:1.5-SNAPSHOT:${authority}'
 * @param {String} urlPathName
 * @param {String} idName
 * @param {String} authorityName
 * @returns {Promise}
 */
export const deleteForPathNameIdAndAuthority = (urlPathName, idName, authorityName) => del(`${dataNamespaceUrl}/${urlPathName}/${idName}:${authorityName}`);

/**
 * @description total request will hit end-point with GET ('/intersectionEntities/search/findByAllocationProjectName?projectName=${intersectionsDetailsForProject}')
 * @param {String} intersectionsDetailsForProject
 * @returns {Promise}
 */
export const getIntersectionsByDetails = (intersectionsDetailsForProject) => get(`${dataNamespaceUrl}/intersectionEntities/search/findByAllocationProjectName?projectName=${intersectionsDetailsForProject}`);

/**
 * @description remove intersection by id with DELETE ('/intersectionEntities=${intersectionId}')
 * @param {String} intersectionId
 * @returns {Promise}
 */
export const deleteIntersectionById = (intersectionId) => del(`${dataNamespaceUrl}/intersectionEntities/${intersectionId}`);

/**
 * @description get allocation by project name with GET ('/allocationEntities/search/findByProjectName?projectName=Axilion License Server')
 * @param {String} projectName
 * @returns {Promise}
 */
export const getAllocationByProject = (projectName) => get(`${dataNamespaceUrl}/allocationEntities/search/findByProjectName?projectName=Axilion License Server`);
