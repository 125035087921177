import {
  TOGGLE_SIDENAV,
} from '../actions/actionTypes';

const initialState = {
  isSideNavOpen: true,
};

const toggleSideNav = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDENAV:
      return { ...state, isSideNavOpen: action.isSideNavOpen };
    default:
      return state;
  }
};

export default toggleSideNav;
