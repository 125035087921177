import { getDataByProvidedArrayOfUrls } from './common-service';
import { getAclEntryEntitiesBySid } from './profile-service';
import { dataNamespaceUrl } from '../constants/url-related';
import { getAclEntryEntitiesGroupByGroupname } from './group-service';

export const getProjectsForUser = async (userForExtractProjects,
  userByUserNameOrGroupByGroupNameService) => {
  let resources;

  let results;

  try {
    const { data: { _embedded: { groupMemberEntities } } } = await userByUserNameOrGroupByGroupNameService(userForExtractProjects);
    const groupMemberEntitiesUrlsForThisUser = groupMemberEntities.map((groupMember) => groupMember._links.group.href);
    const userAssignedToGroups = await getDataByProvidedArrayOfUrls(groupMemberEntitiesUrlsForThisUser);
    const userAssignedToGroupsUrls = userAssignedToGroups.map(
      (item) => `${dataNamespaceUrl}/aclEntryEntities/search/findBySidSid?sid=${item.data.groupName}`,
    );

    let aclEntryEntitiesGroups = await getDataByProvidedArrayOfUrls(userAssignedToGroupsUrls);

    aclEntryEntitiesGroups = aclEntryEntitiesGroups.map((item) => (item.data._embedded.aclEntryEntities));

    const { data } = await getAclEntryEntitiesBySid(userForExtractProjects.username);
    const { _embedded: { aclEntryEntities: aclEntryEntitiesUser } } = data;
    const aclObjectIdentityUrls = aclEntryEntitiesGroups.flat(1).concat(aclEntryEntitiesUser)
      .map((url) => (url._links.aclObjectIdentity.href));

    const aclObjectIdentities = await getDataByProvidedArrayOfUrls(aclObjectIdentityUrls);
    const objectIdIdentitiesUrls = aclObjectIdentities.map((item) => (
      item.data._links.objectIdClass.href));

    const objectIdIdentities = await getDataByProvidedArrayOfUrls(objectIdIdentitiesUrls);
    const featureEntitiesUrls = [];
    const resourceEntitiesUrls = [];

    objectIdIdentities.forEach((identity, index) => {
      if (identity.data.clazz.includes('FeatureEntity')) {
        featureEntitiesUrls.push(
          `${dataNamespaceUrl}/featureEntities/${aclObjectIdentities[index].data.objectIdIdentity}`,
        );
      } else {
        resourceEntitiesUrls.push(
          `${dataNamespaceUrl}/resourceEntities/${aclObjectIdentities[index].data.objectIdIdentity}`,
        );
      }
    });
    const featureAndResourceUrls = featureEntitiesUrls.concat(resourceEntitiesUrls);
    const res = await getDataByProvidedArrayOfUrls(featureAndResourceUrls);

    resources = res.map((resource) => resource.data);
  } catch (err) {
    throw err.message;
  } finally {
    if (resources) {
      resources = resources.map((resource) => ({
        id: resource.id,
        name: resource.name,
        version: resource.version,
        kind: resource.kind,
      }));

      let uniqueArray = resources.map((project) => {
        const versions = [];

        resources.forEach((item) => {
          if (item.name === project.name) {
            versions.push(item.version);
          }
        });

        return {
          id: project.id,
          name: project.name,
          kind: project.kind,
          versions: [...new Set(versions)],
        };
      });

      uniqueArray = uniqueArray.reduce((acc, current) => {
        const x = acc.find((item) => item.name === current.name);

        if (!x) {
          return acc.concat([current]);
        }

        return acc;
      }, []);
      results = {
        projects: uniqueArray.filter((i) => (i.kind === 'PROJECT')),
        applications: uniqueArray.filter((i) => (i.kind === 'APPLICATION')),
      };
    }
  }

  return results;
};

export const getProjectsForGroupName = async (groupName) => {
  const objectHoldTheAclObjectIdentityUrls = await getAclEntryEntitiesGroupByGroupname(groupName);
  const arrayWithAclObjectIdentityUrls = objectHoldTheAclObjectIdentityUrls.data._embedded.aclEntryEntities
    .map((item) => item._links.aclObjectIdentity.href);
  const arrayOfAclObjectIdentities = await getDataByProvidedArrayOfUrls(arrayWithAclObjectIdentityUrls);
  const arrayOfUrlsWithAclObjIdentityEntity = arrayOfAclObjectIdentities
    .map((resource) => resource.data._links.objectIdClass.href);
  const arrayWithFeatureAndResourceUrls = await getDataByProvidedArrayOfUrls(arrayOfUrlsWithAclObjIdentityEntity);
  // Resource can be of kind => 'PROJECT' OR 'APPLICATION'
  const arrayWithResourceEntitiesUrls = [];

  arrayWithFeatureAndResourceUrls
    .forEach((itemUrl, index) => {
      if (itemUrl.data.clazz.includes('ResourceEntity')) {
        arrayWithResourceEntitiesUrls.push(
          `${dataNamespaceUrl}/resourceEntities/${arrayOfAclObjectIdentities[index].data.objectIdIdentity}`,
        );
      }
    });
  const arrayOfResourcesProjectsAndApplications = await getDataByProvidedArrayOfUrls(arrayWithResourceEntitiesUrls);
  const arrayProjectsEntities = arrayOfResourcesProjectsAndApplications.filter((resource) => resource.data.kind === 'PROJECT');
  const arrayProjectsForGroupName = arrayProjectsEntities.map((itemProjectEntity) => itemProjectEntity.data);

  return arrayProjectsForGroupName;
};
