import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from './common';

import { useIsAuthorized } from './utils/hooks/useIsAuthorized';

import { Admin, Group, LicenseDownload, Profile, Users } from './screens';

const AppRouter = () => {
  const isAuthorized = useIsAuthorized();
  const profileRoute = '/profile';

  return (
    <Router>
      <Switch>
        <Route exact path={profileRoute} component={Profile} />
        <PrivateRoute exact path="/admin" component={Admin} publicRoute={profileRoute} isAuthorized={isAuthorized} />
        <PrivateRoute path="/groups/:groupName" component={Group} publicRoute={profileRoute} isAuthorized={isAuthorized} />
        <PrivateRoute path="/users/:userName" component={Users} publicRoute={profileRoute} isAuthorized={isAuthorized} />
        <Route exact path="/license-download/:id" component={LicenseDownload} />
        <Route render={() => <Redirect to={profileRoute} />} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
