import { useSelector, useDispatch } from 'react-redux';
import { fetchCurrentUser } from '../../store/actions';

export const useIsAuthorized = () => {
  const { currentUser, isAdmin } = useSelector((state) => state.currentUserData);
  const dispatch = useDispatch();

  if (!currentUser) {
    dispatch(fetchCurrentUser());
  }

  return isAdmin;
};
