/* eslint-disable max-len */

import { get, post, patch, del, all } from './api';
import { baseUrl, dataNamespaceUrl } from '../constants/url-related';

/**
 * @description Fetch the all data for provided array of urls
 * @param  {Array} arrayOfUrls
 * @returns {Promise}
 */

export const getDataByProvidedArrayOfUrls = (arrayOfUrls) => all(arrayOfUrls);

/**
 * @description Fetch data for provided path where the total request will be /data/${pathname})
 * @param  {String} pathname
 * @returns {Promise}
 */
export const getDataForPathName = (pathname) => get(`${dataNamespaceUrl}/${pathname}`);

/**
 * @description Post data for provided path where the total request is going to be /data/${pathname}, requestObject, headers = {})
 * @param  {String} pathname
 * @param  {Object} requestObject
 * @param  {Object} headers optional
 * @returns {Promise}
 */
export const postDataForPathName = (pathname, requestObject, headers = {}) => post(`${dataNamespaceUrl}/${pathname}`, requestObject, headers);

/**
 * @description Fetch the all data for username total request will be /data/userEntities/${username}/${pathname})
 * @param  {String} username
 * @param  {String} pathname
 * @returns {Promise}
 */
export const getDataByUsernameAndPathname = (username, pathname) => get(`${dataNamespaceUrl}/userEntities/${username}/${pathname}`);

/**
 * @description Update information by id and pathname(userEntities, customerEntities..) and send patch request, total request will be '(/data/${pathname}/com.axilion.service:license:jar:1.5-SNAPSHOT, data)'
 * @param  {String} id
 * @param  {String} pathname
 * @param  {Object} data
 * @returns {Promise}
 */
export const updateDataByIdAndPathname = (id, pathname, data) => patch(`${dataNamespaceUrl}/${pathname}/com.axilion.service:license:jar:1.5-SNAPSHOT`, data);

/**
 * @description Delete data for provided id, total request will be ('/data/groupMemberEntities/com.axilion.service:license:jar:1.5-SNAPSHOT')
 * @param  {String} userName
 * @returns {Promise}
 */
export const deleteGroupMembersById = (id) => del(`${dataNamespaceUrl}/groupMemberEntities/com.axilion.service:license:jar:1.5-SNAPSHOT`);

/**
 * @description Delete data by provided pathname and id, total request will be ('/data/${pathname}/com.axilion.service:license:jar:1.5-SNAPSHOT')
 * @param  {String} userName
 * @returns {Promise}
 */
export const deleteDataByPathnameAndId = (pathname, id) => del(`${dataNamespaceUrl}/${pathname}/com.axilion.service:license:jar:1.5-SNAPSHOT`);

/**
 * @description Fetch the current user, total request will be ('/current-user', {}, headers)
 * @returns {Promise}
 */
export const getCurrentUser = (headers) => get(`${baseUrl}/current-user`, {}, headers);

/**
 * @description  total request will be ('/data/groupMemberEntities/${groupMemberId}/group')
 * @param  {String} groupMemberId
 * @returns {Promise}
 */
export const getGroupMemberEntityById = (groupMemberId) => get(`${dataNamespaceUrl}/groupMemberEntities/${groupMemberId}/group`);


/**
 * @description total request will hit end-point ('/data/groupMemberEntities/search/findByUserUsername?username=${username}')
 * @param {String} username
 * @returns {Promise}
 */
export const getUserByUsername = (username) => get(`${dataNamespaceUrl}/groupMemberEntities/search/findByUserUsername?username=${username}`);
