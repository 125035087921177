import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import uuid from 'uuid';
import {
  AppBar, Tabs, Tab, Paper, Grid, Container,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import {
  getCountByAllocationProjectName,
  deleteForPathNameIdAndAuthority,
  getIntersectionsByDetails,
  getAllocationByProject,
  deleteIntersectionById,
} from '../../api/admin-service';

import {
  updateDataByIdAndPathname,
  getDataByProvidedArrayOfUrls,
  getDataForPathName,
  postDataForPathName,
} from '../../api/common-service';
import { getProjectsForGroupName } from '../../api/complex-fetching-data-service';
import { composeUserObject } from '../../utils/helpers';
import {
  USER_ENTITIES_PATH,
  CUSTOMER_ENTITIES_PATH,
  CERTIFICATE_ENTITIES_PATH,
  AUTHORITIES_ENTITIES_PATH,
  EULA_ENTITIES_PATH,
  GROUP_ENTITIES_PATH,
  AUTHORITY_ENTITIES_PATH,
  GROUP_AUTHORITY_ENTITIES_PATH,
  RESOURCE_ENTITIES_PATH,
  ALLOCATION_ENTITIES_PATH,
  INTERSECTION_ENTITIES,
} from '../../constants/url-related';
import { ADMIN_FORM_DEFAULT_STATE } from '../../constants/admin-form';
import {
  Header,
  SideNav,
} from '../../common';
import renderTabsContent from './renderTabsContent';
import renderDialogsContent from './renderDialogsContent';
import AdminStyles from './Admin.styles';

const useStyles = makeStyles((theme) => (AdminStyles(theme)));

/**
 * @file Admin component. With tabs for USERS, GROUPS, ROLES, PROJECTS, EULA
 */
const Admin = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const TAB_NAMES = [t('users'), t('groups'), t('roles'), t('projects'), t('eula')];
  const [tabsValue, setTabsValue] = useState(TAB_NAMES[0]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [expanded, setExpanded] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [formState, setFormState] = useState(ADMIN_FORM_DEFAULT_STATE);
  const [groupRoles, setGroupRoles] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [ROWS_USERS, setRowUsers] = useState([]);
  const [ROWS_GROUPS, setRowGroups] = useState([]);
  const [ROWS_PROJECTS, setProjects] = useState([]);
  const [ROWS_EULA, setRowEula] = useState([]);
  const [totalIntersections, setTotalIntersections] = useState(0);
  const [usedIntersections, setUsedIntersections] = useState(0);
  const [modifiedUserOrGroupRole, setModifiedUserOrGroupRole] = useState({
    type: '',
    value: '',
  });
  const [projectIntersections, setProjectIntersections] = useState([]);
  const [intersectionsDetailsForProject, setIntersectionDetailsForProject] = useState('');
  const { currentUser } = useSelector((state) => state.currentUserData);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [isLoadingEulas, setIsLoadingEulas] = useState(false);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [isLoadingProjectsForGroup, setIsLoadingProjectsForGroup] = useState(false);
  const [isWholeSignatureVisible, setIsWholeSignatureVisible] = useState(false);
  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const handleIsActive = async (user) => {
    try {
      const propertyToBeUpdated = { enabled: !user.isActive };

      await updateDataByIdAndPathname(user.username, USER_ENTITIES_PATH, propertyToBeUpdated);
    } catch (err) {
      throw err.message;
    } finally {
      const users = filteredUsers.map((filteredUser) => {
        if (filteredUser.email === user.email) {
          const obj = filteredUser;

          obj.isActive = !obj.isActive;

          return obj;
        }

        return filteredUser;
      });

      setFilteredUsers(users);
    }
  };

  const handleSearch = ({ target: { value: v } }) => {
    if (v === '') {
      setFilteredUsers(ROWS_USERS);
      setFilteredGroups(ROWS_GROUPS);

      return;
    }
    const filteredArrUsers = ROWS_USERS.filter(
      (item) => (
        item.name.toLowerCase().includes(v.toLowerCase())
        || item.email.toLowerCase().includes(v.toLowerCase())
        || item.username.toLowerCase().includes(v.toLowerCase())
        || item.companyName.toLowerCase().includes(v.toLowerCase())
      ),
    );
    const filteredArrGroups = ROWS_GROUPS.filter(
      (item) => (
        item.name.toLowerCase().includes(v.toLowerCase())
        || item.projects.join().toLowerCase().includes(v.toLowerCase())
      ),
    );

    setFilteredUsers(filteredArrUsers);
    setFilteredGroups(filteredArrGroups);
  };

  const getGroupRoles = async (id, panel, isExpanded) => {
    let selectedAuthorities = [];

    try {
      if (isExpanded) {
        const groupAuthorities = await getDataForPathName(GROUP_AUTHORITY_ENTITIES_PATH);
        const { data: { _embedded: { groupAuthorityEntities } } } = groupAuthorities;

        selectedAuthorities = groupAuthorityEntities.filter((item) => (item.id.groupId === id));
      }
    } catch (err) {
      throw err.message;
    } finally {
      setGroupRoles(selectedAuthorities);
      setExpanded(isExpanded ? panel : '');
    }
  };

  const getCurrentUserOrGroupRoles = async (username, isExpanded) => {
    let selectedAuthorities = [];

    try {
      if (isExpanded) {
        const groupAuthorities = await getDataForPathName(AUTHORITY_ENTITIES_PATH);
        const { data: { _embedded: { authorityEntities } } } = groupAuthorities;

        selectedAuthorities = authorityEntities.filter((item) => (item.id.username === username));
      }
    } catch (err) {
      throw err.message;
    } finally {
      setUserRoles(selectedAuthorities);
      setExpanded(isExpanded ? username : '');
    }
  };

  const getAllocationEntities = async (id, panel, isExpanded) => {
    let total;

    let used;

    try {
      if (isExpanded) {
        const allocation = await getDataForPathName(ALLOCATION_ENTITIES_PATH);
        const { data: { _embedded: { allocationEntities } } } = allocation;
        const filteredByName = allocationEntities.filter((item) => (item.projectName === panel));

        total = filteredByName.length > 0 ? filteredByName[0].intersections : 0;

        const options = { params: { projectName: panel } };
        const { data } = await getCountByAllocationProjectName(options);

        used = data;
      }
    } catch (err) {
      throw err.message;
    } finally {
      setTotalIntersections(total);
      setUsedIntersections(used);
      setExpanded(isExpanded ? panel : '');
    }
  };

  const handleChange = (panel, id, type) => async (event, isExpanded) => {
    switch (type) {
      case 'user':
        getCurrentUserOrGroupRoles(panel, isExpanded);
        break;
      case 'group':
        getGroupRoles(id, panel, isExpanded);
        break;
      case 'project':
        getAllocationEntities(id, panel, isExpanded);
        break;
      default:
        break;
    }
  };

  const toggleModalVisibility = (isOpen) => {
    setOpenModal(isOpen);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const getAllUsers = async () => {
    setIsLoadingUsers(true);
    let usersInfo = [];

    try {
      const { data:
        { _embedded: { userEntities } } } = await getDataForPathName(USER_ENTITIES_PATH);
      const urlsForNameAndEmail = userEntities.map((url) => (url._links.customers.href));
      const urlsForEulas = userEntities.map((url) => (url._links.eula.href));
      const urlsForCertificates = userEntities.map((url) => (url._links.certificates.href));
      const namesAndEmailsInfo = await getDataByProvidedArrayOfUrls(urlsForNameAndEmail);
      const certificatesInfo = await getDataByProvidedArrayOfUrls(urlsForCertificates);
      const eulasInfo = await getDataByProvidedArrayOfUrls(urlsForEulas);

      usersInfo = namesAndEmailsInfo.map(({ data: { _embedded: { customerEntities: customersData } } }, i) => {
        if (customersData.length > 0) {
          let { data: { _embedded: { certificateEntities } } } = certificatesInfo[i];

          certificateEntities = certificateEntities.sort((a, b) => (new Date(b.createdDate) - new Date(a.createdDate)));

          return composeUserObject(customersData[0], userEntities[i], eulasInfo[i].data, certificateEntities[0] || { organization: 'N/A' });
        }

        return undefined;
      }).filter((el) => (el !== undefined));
    } catch (err) {
      throw err.message;
    } finally {
      setRowUsers(usersInfo);
      setFilteredUsers(usersInfo);
      setIsLoadingUsers(false);
    }
  };

  const resetFromState = () => {
    setFormState(ADMIN_FORM_DEFAULT_STATE);
  };

  const addNewUser = async () => {
    if (
      formState.username.trim() === ''
      || formState.firstname.trim() === ''
      || formState.lastname.trim() === ''
      || formState.email.trim() === ''
      || formState.company.trim() === ''
      || formState.organizationalUnit.trim() === ''
      || formState.country.trim() === ''
      || formState.location.trim() === ''
      || formState.state.trim() === ''
    ) {
      return;
    }
    const headers = { 'Content-Type': 'application/hal+json', Accept: 'application/hal+json' };

    const userRequest = {
      username: formState.username.trim(),
      enabled: true,
    };

    try {
      const { data: userData } = await postDataForPathName(USER_ENTITIES_PATH, userRequest);
      const { _links: {
        eula: { href: eulaUrl },
      } } = userData;

      const customerRequest = {
        firstName: formState.firstname.trim(),
        lastName: formState.lastname.trim(),
        email: formState.email.trim(),
        user: userData._links.self.href,
      };
      const certificateRequest = {
        organization: formState.company.trim(),
        organizationalUnit: formState.organizationalUnit.trim(),
        country: formState.country.trim(),
        location: formState.location.trim(),
        state: formState.state.trim(),
        user: userData._links.self.href,
        reservationId: uuid.v4(),
        issued: `${format(new Date(), 'yyy-MM-dd')}T${format(new Date(), 'HH:mm:ss')}Z`,
        valid: true,
        validityDays: 365,
      };
      const authorityRequest = {
        id: {
          username: formState.username,
          authority: 'ROLE_CUSTOMER',
        },
      };

      const { data: customerData } = await postDataForPathName(CUSTOMER_ENTITIES_PATH, customerRequest, headers);

      await postDataForPathName(CERTIFICATE_ENTITIES_PATH, certificateRequest, headers);
      await postDataForPathName(AUTHORITIES_ENTITIES_PATH, authorityRequest, headers);

      const arrayOfUrls = [eulaUrl];
      const [eula] = await getDataByProvidedArrayOfUrls(arrayOfUrls);
      const newUser = composeUserObject(customerData, userData, eula.data, { organization: formState.company });
      const usersWithTheNewOne = [...ROWS_USERS, newUser];

      setRowUsers(usersWithTheNewOne);
      setFilteredUsers(usersWithTheNewOne);
    } catch (err) {
      throw err.message;
    } finally {
      toggleModalVisibility(false);
      resetFromState();
    }
  };

  const getAllGroups = async () => {
    setIsLoadingGroups(true);
    let groupsInfo = [];

    try {
      const { data:
        { _embedded: { groupEntities } } } = await getDataForPathName(GROUP_ENTITIES_PATH);

      groupsInfo = groupEntities.map(({ groupName, createdDate, id }) => ({
        id,
        name: groupName,
        description: 'N/A',
        createDate: format(new Date(createdDate), 'yyyy/MM/dd'),
        projects: [],
      }));
    } catch (err) {
      throw err.message;
    } finally {
      setRowGroups(groupsInfo);
      setFilteredGroups(groupsInfo);
      setIsLoadingGroups(false);
    }
  };

  const getAllEulas = async () => {
    setIsLoadingEulas(true);
    let eulas;

    try {
      const EULAs = await getDataForPathName(EULA_ENTITIES_PATH);
      const { data: { _embedded: { eulaEntities } } } = EULAs;

      eulas = eulaEntities;
      const urlsForUsers = eulaEntities.map((url) => (url._links.users.href));
      const usersPerEula = await getDataByProvidedArrayOfUrls(urlsForUsers);
      const eulaUsers = usersPerEula.map((user) => (user.data._embedded.userEntities));

      eulas = eulas.map((eula, i) => ({ ...eula, usersAssigned: eulaUsers[i].length }));
    } catch (err) {
      throw err.message;
    } finally {
      setRowEula(eulas);
      setIsLoadingEulas(false);
    }
  };

  const getAllProjects = async () => {
    setIsLoadingProjects(true);
    let projects;

    try {
      const resources = await getDataForPathName(RESOURCE_ENTITIES_PATH);
      const { data: { _embedded: { resourceEntities } } } = resources;

      projects = resourceEntities.filter((item) => (item.kind === 'PROJECT'));
      projects = projects.map((project) => {
        const versions = [];

        projects.forEach((item) => {
          if (item.name === project.name) {
            versions.push(item.version);
          }
        });

        return {
          id: project.id,
          name: project.name,
          versions,
        };
      });
      projects = projects.filter((e, i) => projects.findIndex((a) => a.name === e.name) === i);
    } catch (err) {
      throw err.message;
    } finally {
      setProjects(projects);
      setIsLoadingProjects(false);
    }
  };

  const deleteGroupAuthority = (id, authority) => async () => {
    await deleteForPathNameIdAndAuthority(GROUP_AUTHORITY_ENTITIES_PATH, id, authority);
    getGroupRoles(id);
  };

  const deleteUserAuthority = (username, authority) => async () => {
    await deleteForPathNameIdAndAuthority(AUTHORITY_ENTITIES_PATH, username, authority);
    getCurrentUserOrGroupRoles(username, true);
  };

  const addNewGroup = async () => {
    if (formState.groupname.trim() === '') {
      return;
    }
    const groupRequest = {
      groupName: formState.groupname.trim(),
    };

    try {
      const { data } = await postDataForPathName(GROUP_ENTITIES_PATH, groupRequest);
      const newGroup = {
        id: data.id,
        name: data.groupName,
        description: 'N/A',
        createDate: format(new Date(data.createdDate), 'yyyy/MM/dd'),
        projects: [''],
      };

      const newArrayGroups = [...ROWS_GROUPS, newGroup];

      setRowGroups(newArrayGroups);
      setFilteredGroups(newArrayGroups);
    } catch (err) {
      throw err.message;
    } finally {
      toggleModalVisibility(false);
      resetFromState();
    }
  };

  const addNewRole = async () => {
    if (formState.rolename.trim() === '') {
      return;
    }

    const data = {
      id: {
        authority: formState.rolename,
      },
    };

    data.id[modifiedUserOrGroupRole.type === 'group' ? 'groupId' : 'username'] = modifiedUserOrGroupRole.value;
    const endPoint = modifiedUserOrGroupRole.type === 'group' ? GROUP_AUTHORITY_ENTITIES_PATH : AUTHORITY_ENTITIES_PATH;

    try {
      await postDataForPathName(endPoint, data);
    } catch (err) {
      throw err.message;
    } finally {
      getGroupRoles();
      toggleModalVisibility(false);
      resetFromState();
    }
  };

  const addNewEula = async () => {
    if (formState.eulaVersion.trim() === '') {
      return;
    }
    const eulaRequest = {
      version: formState.eulaVersion.trim(),
    };

    try {
      const responseEula = await postDataForPathName(EULA_ENTITIES_PATH, eulaRequest);
      const { data: { id, createdBy, createdDate, modifiedDate, version, _links } } = responseEula;
      const { users: { href: usersToEulaUrl } } = _links;
      const [{ data: { _embedded: { userEntities: users } } }] = await getDataByProvidedArrayOfUrls([usersToEulaUrl]);
      const newEulaToBeAdded = { id, createdBy, createdDate, modifiedDate, version, usersAssigned: users.length };

      setRowEula([...ROWS_EULA, newEulaToBeAdded]);
    } catch (err) {
      throw err.message;
    } finally {
      toggleModalVisibility(false);
      resetFromState();
    }
  };

  const getIntersectionsDetails = async () => {
    let intersections;

    try {
      // eslint-disable-next-line max-len
      const { data: { _embedded: { intersectionEntities } } } = await getIntersectionsByDetails(intersectionsDetailsForProject);

      intersections = intersectionEntities.sort((a, b) => (Number(a.identifier) - Number(b.identifier)));
      setProjectIntersections(intersections);
    } catch (err) {
      throw err.message;
    }
  };

  const getProjectsForCurrGroup = () => {
    const arrayOfAllProjectsWithAllGroups = ROWS_GROUPS.map(async (group) => {
      const modifiedGroup = group;
      const projectsForCurrentGroup = await getProjectsForGroupName(modifiedGroup.name);
      const projectsNamesForCurrentGroup = projectsForCurrentGroup.map((projectForCurrentGroup) => projectForCurrentGroup.name);
      const uniqueProjectsForCurrentGroup = [...new Set(projectsNamesForCurrentGroup)];

      modifiedGroup.projects = uniqueProjectsForCurrentGroup;

      return modifiedGroup;
    });

    return arrayOfAllProjectsWithAllGroups;
  };

  const addNewIntersection = async (projectName) => {
    if (formState.identifier.trim() === '') {
      return;
    }

    try {
      const { data: { _links: { allocationEntity: { href: allocation } } } } = await getAllocationByProject(projectName);

      await postDataForPathName(INTERSECTION_ENTITIES, {
        allocation,
        identifier: formState.identifier.trim(),
        signature: '{canvasSize:{width:200,height:200},perimeter:[],canvasShapes:[]}',
      });
    } catch (err) {
      throw err.message;
    } finally {
      let used = usedIntersections;

      used += 1;
      getIntersectionsDetails();
      setFormState(ADMIN_FORM_DEFAULT_STATE);
      setUsedIntersections(used);
    }
  };

  const removeIntersection = async (intersection) => {
    try {
      await deleteIntersectionById(intersection.id);
    } catch (err) {
      throw err.message;
    } finally {
      let used = usedIntersections;

      used -= 1;
      getIntersectionsDetails();
      setUsedIntersections(used);
    }
  };

  const renderIntersectionSignature = (signature) => {
    if (signature && signature.length > 74) {
      return `${signature.substring(0, 100)}...`;
    }

    return signature;
  };

  /**
   * @function useEffect, fetch data for users data, groups data, eula data and projects data
   */
  useEffect(() => {
    getAllUsers();
    getAllGroups();
    getAllEulas();
    getAllProjects();

    // eslint-disable-next-line
  }, [history]);

  /**
   * @function useEffect, get(fetch data) for all projects for current group, show spinner in the rows during fetching the projects
   */
  useEffect(() => {
    if (!isLoadingProjectsForGroup) {
      setIsLoadingProjectsForGroup(true);
      const wrapperFunctionForAsyncAwait = async () => {
        const arrayOfAllProjectsWithAllGroups = await getProjectsForCurrGroup();

        const groups = await Promise.all(arrayOfAllProjectsWithAllGroups);

        setRowGroups(groups);
        setFilteredGroups(groups);
        setIsLoadingProjectsForGroup(false);
      };

      wrapperFunctionForAsyncAwait();
    }
    // eslint-disable-next-line
  }, [isLoadingProjects]);
  /**
   * @function useEffect, get(fetch data) for intersections
   */
  useEffect(() => {
    getIntersectionsDetails();
    // eslint-disable-next-line
  }, [intersectionsDetailsForProject]);

  useEffect(() => {
    setFilteredUsers(ROWS_USERS);
    setFilteredGroups(ROWS_GROUPS);
  }, [tabsValue, ROWS_USERS, ROWS_GROUPS]);

  return (
    <div className={classes.root}>
      <Header
        title="Administration"
        name={currentUser}
      />
      <SideNav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <AppBar position="static" color="default" className={classes.tabs}>
                  <Tabs
                    value={tabsValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {TAB_NAMES.map((tab) => (<Tab key={tab} value={tab} label={tab} />))}
                  </Tabs>
                </AppBar>
                {renderTabsContent(
                  tabsValue,
                  isLoadingUsers,
                  t,
                  handleSearch,
                  classes,
                  toggleModalVisibility,
                  filteredUsers,
                  currentUser,
                  handleIsActive,
                  isLoadingGroups,
                  isLoadingProjectsForGroup,
                  filteredGroups,
                  ROWS_GROUPS,
                  expanded,
                  handleChange,
                  groupRoles,
                  deleteGroupAuthority,
                  setModifiedUserOrGroupRole,
                  ROWS_USERS,
                  userRoles,
                  deleteUserAuthority,
                  isLoadingProjects,
                  ROWS_PROJECTS,
                  usedIntersections,
                  totalIntersections,
                  setIntersectionDetailsForProject,
                  isLoadingEulas,
                  ROWS_EULA,
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
      {
        renderDialogsContent(
          tabsValue,
          t,
          classes,
          openModal,
          toggleModalVisibility,
          handleOnChange,
          addNewUser,
          addNewGroup,
          modifiedUserOrGroupRole,
          addNewRole,
          formState,
          intersectionsDetailsForProject,
          addNewIntersection,
          removeIntersection,
          renderIntersectionSignature,
          isWholeSignatureVisible,
          setIsWholeSignatureVisible,
          projectIntersections,
          addNewEula,
        )
      }
    </div>
  );
};

export default Admin;
