import React from 'react';
import propTypes from 'prop-types';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { format } from 'date-fns';

const EulasTable = ({ headers, eulas }) => (
  <Table>
    <TableHead>
      <TableRow>
        {headers.map((header) => (<TableCell key={header}>{header}</TableCell>))}
      </TableRow>
    </TableHead>
    <TableBody>
      {eulas.map((row) => (
        <TableRow key={row.id}>
          <TableCell>{`v ${row.version}`}</TableCell>
          <TableCell>{format(new Date(row.createdDate), 'yyyy/MM/dd')}</TableCell>
          <TableCell>{format(new Date(row.modifiedDate), 'yyyy/MM/dd (hh:mm a)')}</TableCell>
          <TableCell>{row.usersAssigned}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

EulasTable.propTypes = {
  headers: propTypes.array.isRequired,
  eulas: propTypes.array.isRequired,
};

export default EulasTable;
