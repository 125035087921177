import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid,
  List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ListPanelStyles from './ListPanel.styles';

const useStyles = makeStyles(() => (ListPanelStyles()));

const renderTitle = (isGroup, hasItems, t) => {
  if (isGroup) {
    return hasItems ? t('Groups') : t('noGroups');
  }

  return hasItems ? t('Users') : t('noUsers');
};

const ListPanel = ({
  items,
  expanded,
  handleChange,
  subList,
  deleteAction,
  setAction,
  toggleModalVisibility,
  isGroup,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography component="h2" variant="inherit" color="primary">
        {renderTitle(isGroup, items.length > 0, t)}
        :
      </Typography>
      {items.map((item) => (
        <ExpansionPanel
          key={item.name}
          expanded={isGroup ? expanded === item.name : expanded === item.username}
          onChange={handleChange(isGroup ? item.name : item.username, item.id, isGroup ? 'group' : 'user')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {isGroup ? <Typography className={classes.heading}>{item.name}</Typography>
              : (
                <Typography className={classes.heading}>
                  {t('tableHeaders.name')}
                  :
                  <b>{item.name}</b>
                  /
                  {t('tableHeaders.username')}
                  :
                  <b>{item.username}</b>
                </Typography>
              )}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <List>
                  {subList.length > 0 ? (
                    subList.map((role) => {
                      if (role.id.authority === item.name || role.id.authority === item.username) {
                        return (null);
                      }

                      return (
                        <ListItem key={role.id.authority}>
                          <ListItemText
                            primary={role.id.authority}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={deleteAction(isGroup ? role.id.groupId : role.id.username, role.id.authority)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })
                  )
                    : (
                      <ListItem>
                        <ListItemText
                          primary={t('noRolesAssigned')}
                        />
                      </ListItem>
                    )}
                </List>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
          <Box display="flex" flex="1">
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => {
                setAction({
                  type: isGroup ? 'group' : 'user',
                  value: isGroup ? item.id : item.username,
                });
                toggleModalVisibility(true);
              }}
              className={classes.iconButton}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </ExpansionPanel>
      ))}
    </>
  );
};

ListPanel.propTypes = {
  items: propTypes.array.isRequired,
  expanded: propTypes.bool.isRequired,
  handleChange: propTypes.func.isRequired,
  subList: propTypes.array.isRequired,
  deleteAction: propTypes.func.isRequired,
  setAction: propTypes.func.isRequired,
  toggleModalVisibility: propTypes.func.isRequired,
  isGroup: propTypes.bool,
};

ListPanel.defaultProps = {
  isGroup: false,
};

export default ListPanel;
